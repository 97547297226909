/* Toastfy */
/* Toastfy */

import { TextField } from "@mui/material";
import "../styles/ContactComment.css";
import React, { useState } from "react";
import { FaRegComment } from "react-icons/fa";
import { VscClose } from "react-icons/vsc";
import Edit from "../clouboard_logo/Edit.svg";
import Delete from "../clouboard_logo/Delete.svg";
import Star from "../clouboard_logo/Star.svg";
import { useDispatch, useSelector } from "react-redux";
import { editComment, setCommentData } from "../Features/CommentSlice";
import {
  fetchComment,
  postComment,
  deleteComment,
  getComment,
} from "../Features/CommentSlice";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { styled } from "@mui/material/styles";
import CommentDisplay from "./CommentDisplay";

export default function ContactsCommentModal({ id }) {
  /* Toastify */

  const csstextfield = styled(TextField)({
    "& label": {
      // eğer laellerı focus olmadan ortalamak ıstersek
      width: "90%",
      textAlign: "center",
    },
    /*Settings Son */
    "& label.Mui-focused": {
      color: "#fd624c",

      textAlign: "center",
      transformOrigin: "center",
      "&.Mui-focused": {
        transformOrigin: "center",
        marginLeft: "0 auto",
      },
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fd624c",
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fd624c",
      },
      "&:hover fieldset": {
        borderColor: "#fd624c",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fd624c",
        textAlign: "center",
      },
    },
  });

  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState("");

  const { comment, editCommentData } = useSelector((state) => state.comment);

  let timeStamp; // değişken tanımladım
  /* Dinamık yerler */
  const [commentValues, setCommentValues] = useState({
    customer_id: id || "", // normal id alıcak
    due_date: new Date(), // veri cıkısı yaparken sayı olması gerekıyor moment(`${due_date}`).unix("X"),
    comment: "",
  });

  const handleChange = (prop) => (event) => {
    setCommentValues({
      ...commentValues,
      [prop]: event.target.value,
    });
  };

  const handleDate = (date) => {
    setStartDate(date);
    setCommentValues({
      ...commentValues,
      due_date: date,
    });
  };

  const dateInTarih = new Date(commentValues.due_date).getTime();
  timeStamp = Math.trunc(dateInTarih / 1000);

  // setCommentValues({ ...commentValues, due_date: timeStamp }); // stampe cevirdi bunu deneyelım uygulamda
  // console.log("timeStamp", timeStamp, "TypeOf: ", timeStamp);

  // Apiden timestamp gelince kullanıcam
  // const date = moment.unix(timeStamp).format("DD.MM.YYYY");

  const handlePost = () => {
    dispatch(
      postComment({
        ...commentValues,
        due_date: timeStamp,
      })
    );
    setCommentValues({
      due_date: "",
      comment: "",
    });
  };

  return (
    <>
      <span
        data-bs-toggle="modal"
        data-bs-target="#commentModal"
        className="ms-3"
      >
        <FaRegComment onClick={() => dispatch(fetchComment(id))} />
      </span>
      <div
        className="modal fade"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        id="commentModal"
      >
        <div jsx="true" className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h6 className="modal-title mx-auto" id="exampleModalLabel">
                <img width={30} src={Edit} alt="" className="mx-auto" />
                Comments
              </h6>
              <button
                style={{
                  backgroundColor: "#fd624c",
                  color: "#fff",
                  fontWeight: "bold",
                  outline: "",
                }}
                className="btn text-end "
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <VscClose />
              </button>
            </div>
            <div className="modal-body">
              <div className="container">
                <div className="row  ">
                  <div className="col d-flex flex-column flex-sm-row">
                    <div className="col-2 mx-2 ">
                      <span className="d-flex addComment ">Add Comment</span>
                    </div>
                    <div
                      className="hr"
                      style={{
                        borderRight: "1px solid gray",
                        height: "300px",
                      }}
                    />
                    <div className="col-10 ms-3 p-0">
                      <div className="row  d-flex  justify-content-between">
                        <div className="col-sm my-2">
                          <h6 className=" p-auto fw-bold">Comment</h6>
                        </div>
                        <div className="col-sm px-3 text-end mt-3">
                          <DatePicker
                            selected={startDate}
                            onChange={handleDate}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <TextField
                          sx={{ color: "red" }}
                          InputProps={{ csstextfield }}
                          id="outlined-multiline-static"
                          multiline
                          rows="3"
                          value={commentValues.comment}
                          onChange={handleChange("comment")}
                          className="mt-3 col-12 col-lg-12 my-2 p-1"
                          label="Comment..."
                          variant="outlined"
                        />
                      </div>
                      <div className="row mt-2 d-flex  justify-content-between">
                        <div className="col my-1 ">
                          <span
                            style={{
                              backgroundColor: "#e4e9ef",
                              marginTop: "10px",
                              whiteSpace: "nowrap",
                            }}
                            className="text-muted rounded p-2 ms-1"
                          >
                            + Add users
                          </span>
                        </div>
                        <div className="col text-end px-3">
                          <button
                            onClick={handlePost}
                            className="btn"
                            style={{
                              backgroundColor: "#fd624c",
                              color: "#fff",
                              padding: "5px 30px",
                              fontWeight: "bold",
                            }}
                          >
                            SEND
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="hr"
                    style={{
                      borderBottom: "1px solid gray",
                      width: "100%",
                    }}
                  />
                </div>
                <div className="row">
                  <div className="col d-flex flex-column flex-sm-row">
                    <div className="hrs" />
                    <div className="col-2 mx-2">
                      <ul className="nav flex-row flex-md-column">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            aria-current="page"
                            href="#All"
                          >
                            All
                          </a>
                        </li>
                        <li className="nav-item ">
                          <a href="#Client" className="nav-link text-dark ">
                            Client
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div
                      className="hr"
                      style={{
                        borderRight: "1px solid gray",
                        height: "100%",
                      }}
                    />
                    <div className=" col-10 d-flex flex-column">
                      {comment &&
                        comment.map((item, idx) => {
                          return (
                            <div key={idx} className="col-12 ms-3  p-0">
                              <div className="row  d-flex    justify-content-between">
                                <div className="col-sm mt-3">
                                  <div className="col-sm d-flex justify-content-between">
                                    <h5>Yavuz Durukan</h5>
                                    <div>
                                      <label className="text-muted">
                                        Date:
                                      </label>
                                      <span className="ms-2 text-muted ">
                                        {moment
                                          .unix(item.due_date)
                                          .format("DD.MM.YYYY")}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col">
                                <div
                                  className="p-1"
                                  style={{
                                    backgroundColor: "#ffcdd2",
                                    borderRadius: "7px",
                                  }}
                                >
                                  <CommentDisplay comment={item.comment} />
                                  <div className=" d-flex justify-content-end">
                                    <img
                                      width={25}
                                      src={Star}
                                      alt="Favoriler"
                                    />

                                    {/*EDİT MODAL EN ALTTA */}
                                    <span
                                      data-bs-toggle="modal"
                                      data-bs-target="#abccba"
                                    >
                                      <img
                                        onClick={() => {
                                          dispatch(getComment(item.id));
                                        }}
                                        width={25}
                                        src={Edit}
                                        alt="Edit"
                                      />
                                    </span>

                                    <img
                                      onClick={() => {
                                        dispatch(deleteComment(item.id));
                                      }}
                                      width={25}
                                      src={Delete}
                                      alt="Delete"
                                    />
                                  </div>
                                </div>
                                <div
                                  className="hr mt-5"
                                  style={{
                                    borderBottom: "1px solid gray",
                                    width: "100%",
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*EDİT MODAL */}
      {/* const dateInTarih = new Date(commentValues.due_date).getTime();
  timeStamp = Math.trunc(dateInTarih / 1000); */}
      {editCommentData && (
        <div
          className="modal fade"
          id="abccba"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Comment Edit
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <>
                  <div jsx="true" className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-body">
                        <div className="container">
                          <div className="row  ">
                            <div className="col d-flex  flex-column flex-sm-row">
                              <div className="col m-0 p-0">
                                <div className="row  d-flex  justify-content-between">
                                  <div className="col-12 ms-1 mt-3">
                                    <DatePicker
                                      selected={startDate}
                                      // onChange={handleDate}

                                      value={moment
                                        .unix(editCommentData.due_date)
                                        .format("YYYY-MM-DD")}
                                      type="date"
                                      name="due_date"
                                      onChange={(e) =>
                                        dispatch(
                                          setCommentData({
                                            name: e.target.name,
                                            value: e.target.value,
                                          })
                                        )
                                      }
                                      style={{
                                        color: "#fd624c",
                                        border: "1px solid #fd624c",
                                        padding: "10px 25px",
                                        borderRadius: "7px",
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-12">
                                  <TextField
                                    InputProps={{ ...csstextfield }}
                                    id="outlined-multiline-static"
                                    multiline
                                    rows="3"
                                    value={editCommentData.comment}
                                    name="comment"
                                    onChange={(e) =>
                                      dispatch(
                                        setCommentData({
                                          name: e.target.name,
                                          value: e.target.value,
                                        })
                                      )
                                    }
                                    className="mt-3 col-12 col-lg-12 my-2 p-1"
                                    label="Comment..."
                                    variant="outlined"
                                  />
                                </div>
                                <div className="row mt-2 d-flex  justify-content-between">
                                  <div className="col my-1 ">
                                    <span
                                      style={{
                                        backgroundColor: "#e4e9ef",
                                        marginTop: "10px",
                                        whiteSpace: "nowrap",
                                      }}
                                      className="text-mutedrounded p-2 ms-1 "
                                    >
                                      + Add users
                                    </span>
                                  </div>
                                  <div className="col text-end px-3">
                                    <button
                                      // put ıslemı burada olucak
                                      data-bs-dismiss="modal"
                                      onClick={() => {
                                        let editTimeStamp;
                                        const dateInTarih = new Date(
                                          editCommentData.due_date
                                        ).getTime();
                                        editTimeStamp = Math.trunc(
                                          dateInTarih / 1000
                                        );
                                        dispatch(
                                          editComment({
                                            ...editCommentData,
                                            due_date: editTimeStamp,
                                          })
                                        );
                                      }}
                                      className="btn"
                                      style={{
                                        backgroundColor: "#fd624c",
                                        color: "#fff",
                                        padding: "5px 30px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      SEND
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
