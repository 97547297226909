import React from "react";
import "../styles/Navbar.css";
import "../styles/Satis.css";

// Logolar and
export default function Satis() {
  return (
    <div className="Satis">
      <div className="containers ">
        <div className="row ">
          <div className="col mt-5"></div>
        </div>

        <div className=" mx-2 mt-1 row  d-flex px-0 ">
          <div className="col-md-3   col-sm-6  col-12 g-1">
            <div className="card shadow" style={{ minWidth: "10rem" }}>
              <div className="card-body py-3 d-flex justify-content-between">
                <div>
                  <h5 style={{ whiteSpace: "nowrap" }} className="card-title ">
                    Ön Görüşme
                  </h5>
                  <span className="text-muted col-lg-ms-1">$1,000,000</span>
                </div>
                <div>
                  <span className="rounded-circle p-1 ">20</span>
                </div>
              </div>
              <div className="card-body p-3 d-flex">
                <div className="me-2">
                  <div className="profile" style={{ padding: "10px" }} />
                </div>
                <div>
                  <h5 className="d-flex">Hatice Durukan </h5>
                  <div className="d-flex flex-column">
                    <span className="text-muted">weboost.dev</span>
                    <span className="text-muted">70.000TL</span>
                    <p className="text-muted fs-6 ">
                      Yarın: Proje müdürünü aras
                    </p>
                  </div>
                </div>
              </div>
              <div className="card-body p-3 d-flex">
                <div className="me-2">
                  <div className="profile" style={{ padding: "10px" }} />
                </div>
                <div>
                  <h5>Hatice Durukan</h5>
                  <div className="d-flex flex-column">
                    <span className="text-muted">weboost.dev</span>
                    <span className="text-muted">70.000TL</span>
                    <p className="text-muted">Yarın: Proje müdürünü ara</p>
                  </div>
                </div>
              </div>
              <div className="card-body p-3">
                <div className="p-1"></div>
              </div>
            </div>
          </div>
          <div className="col-md-3   col-sm-6 col-12 g-1">
            <div className="card shadow " style={{ minWidth: "10rem" }}>
              <div className="card-body py-3 d-flex justify-content-between">
                <div>
                  <h5 className="card-title">Sunum</h5>
                  <span className="text-muted">$1,000,000</span>
                </div>
                <div>
                  <span className="rounded-circle p-1 ">20</span>
                </div>
              </div>
              <div className="card-body p-3 d-flex">
                <div className="me-2">
                  <div className="profile" style={{ padding: "10px" }} />
                </div>
                <div>
                  <h5 className="d-flex">Hatice Durukan </h5>
                  <div className="d-flex flex-column">
                    <span className="text-muted">weboost.dev</span>
                    <span className="text-muted">70.000TL</span>
                    <p className="text-muted fs-6 ">
                      Yarın: Proje müdürünü aras
                    </p>
                  </div>
                </div>
              </div>
              <div className="card-body p-3 d-flex">
                <div className="me-2">
                  <div className="profile" style={{ padding: "10px" }} />
                </div>
                <div>
                  <h5>Hatice Durukan</h5>
                  <div className="d-flex flex-column">
                    <span className="text-muted">weboost.dev</span>
                    <span className="text-muted">70.000TL</span>
                    <p className="text-muted">Yarın: Proje müdürünü ara</p>
                  </div>
                </div>
              </div>
              <div className="card-body p-3">
                <div className="p-1"></div>
              </div>
            </div>
          </div>
          <div className="col-md-3   col-sm-6 col-12 g-1">
            <div className="card shadow" style={{ minWidth: "10rem" }}>
              <div className="card-body py-3 d-flex justify-content-between">
                <div>
                  <h5 className="card-title">Sunum</h5>
                  <span className="text-muted">$1,000,000</span>
                </div>
                <div>
                  <span className="rounded-circle p-1 ">20</span>
                </div>
              </div>
              <div className="card-body p-3 d-flex">
                <div className="me-2">
                  <div className="profile" style={{ padding: "10px" }} />
                </div>
                <div>
                  <h5 className="d-flex">Hatice Durukan </h5>
                  <div className="d-flex flex-column">
                    <span className="text-muted">weboost.dev</span>
                    <span className="text-muted">70.000TL</span>
                    <p className="text-muted fs-6 ">
                      Yarın: Proje müdürünü aras
                    </p>
                  </div>
                </div>
              </div>
              <div className="card-body p-3 d-flex">
                <div className="me-2">
                  <div className="profile" style={{ padding: "10px" }} />
                </div>
                <div>
                  <h5>Hatice Durukan</h5>
                  <div className="d-flex flex-column">
                    <span className="text-muted">weboost.dev</span>
                    <span className="text-muted">70.000TL</span>
                    <p className="text-muted">Yarın: Proje müdürünü ara</p>
                  </div>
                </div>
              </div>
              <div className="card-body p-3">
                <div className="p-1"></div>
              </div>
            </div>
          </div>
          <div className="col-md-3   col-sm-6 col-12 g-1">
            <div className="card shadow " style={{ minWidth: "10rem" }}>
              <div className="card-body py-3 d-flex justify-content-between">
                <div>
                  <h5 className="card-title">Sunum</h5>
                  <span className="text-muted">$1,000,000</span>
                </div>
                <div>
                  <span className="rounded-circle p-1 ">20</span>
                </div>
              </div>
              <div className="card-body p-3 d-flex">
                <div className="me-2">
                  <div className="profile" style={{ padding: "10px" }} />
                </div>
                <div>
                  <h5 className="d-flex">Hatice Durukan </h5>
                  <div className="d-flex flex-column">
                    <span className="text-muted">weboost.dev</span>
                    <span className="text-muted">70.000TL</span>
                    <p className="text-muted fs-6 ">
                      Yarın: Proje müdürünü aras
                    </p>
                  </div>
                </div>
              </div>
              <div className="card-body p-3 d-flex">
                <div className="me-2">
                  <div className="profile" style={{ padding: "10px" }} />
                </div>
                <div>
                  <h5>Hatice Durukan</h5>
                  <div className="d-flex flex-column">
                    <span className="text-muted">weboost.dev</span>
                    <span className="text-muted">70.000TL</span>
                    <p className="text-muted">Yarın: Proje müdürünü ara</p>
                  </div>
                </div>
              </div>
              <div className="card-body p-3">
                <div className="p-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  accordion */}
      <div className="containerMobile">
        <div className="row ">
          <div className="col  d-flex justify-content-center flex-column ">
            <div
              className="accordion  mx-auto accordion-flush mb-2 mt-5 "
              id="accordionFlushExample"
            >
              <div
                className="card accordion-item p-3 "
                style={{ width: "18rem" }}
              >
                <div className="">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <span
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      <div>
                        <div className="">
                          <span className="rounded-circle">10</span>
                        </div>
                        <div className="badges">
                          <h5
                            style={{ whiteSpace: "nowrap" }}
                            className="card-title  "
                          >
                            Ön Görüşme
                          </h5>
                        </div>
                        <span className="text-muted col-lg-ms-1">
                          $1,000,000
                        </span>
                      </div>
                    </span>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body px-0">
                      <div className="card-body">
                        <div className="d-flex">
                          <div className="me-2">
                            <div
                              className="profile"
                              style={{ padding: "10px" }}
                            />
                          </div>
                          <div>
                            <h5 className="d-flex">Hatice Durukan </h5>
                            <div className="d-flex flex-column">
                              <span className="text-muted">weboost.dev</span>
                              <span className="text-muted">70.000TL</span>
                              <p className="text-muted fs-6 ">
                                Yarın: Proje müdürünü aras
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body p-3 d-flex">
                        <div className="me-2">
                          <div
                            className="profile"
                            style={{ padding: "10px" }}
                          />
                        </div>
                        <div>
                          <h5 className="d-flex">Hatice Durukan </h5>
                          <div className="d-flex flex-column">
                            <span className="text-muted">weboost.dev</span>
                            <span className="text-muted">70.000TL</span>
                            <p className="text-muted fs-6 ">
                              Yarın: Proje müdürünü aras
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card-body p-3 d-flex">
                        <div className="me-2">
                          <div
                            className="profile"
                            style={{ padding: "10px" }}
                          />
                        </div>
                        <div>
                          <h5>Hatice Durukan</h5>
                          <div className="d-flex flex-column">
                            <span className="text-muted">weboost.dev</span>
                            <span className="text-muted">70.000TL</span>
                            <p className="text-muted">
                              Yarın: Proje müdürünü ara
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="card accordion-item p-3 mt-2 "
                style={{ width: "18rem" }}
              >
                <div className="">
                  <h2 className="accordion-header" id="flush-headingTwo">
                    <span
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      <div>
                        <div>
                          <span className="rounded-circle">10</span>
                        </div>
                        <div className="badges">
                          <h5
                            style={{ whiteSpace: "nowrap" }}
                            className="card-title"
                          >
                            Sunum
                          </h5>
                        </div>
                        <span className="text-muted col-lg-ms-1">
                          $1,485,000
                        </span>
                      </div>
                    </span>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body px-0">
                      <div className="card-body p-3 d-flex">
                        <div className="me-2">
                          <div
                            className="profile"
                            style={{ padding: "10px" }}
                          />
                        </div>
                        <div>
                          <h5>Hatice Durukan</h5>
                          <div className="d-flex flex-column">
                            <span className="text-muted">weboost.dev</span>
                            <span className="text-muted">70.000TL</span>
                            <p className="text-muted">
                              Yarın: Proje müdürünü ara
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="card accordion-item p-3 mt-2 "
                style={{ width: "18rem" }}
              >
                <div className="">
                  <h2 className="accordion-header" id="flush-headingThere">
                    <span
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThere"
                      aria-expanded="false"
                      aria-controls="flush-collapseThere"
                    >
                      <div>
                        <div className="">
                          <span className="rounded-circle">10</span>
                        </div>
                        <div className="badges">
                          <h5
                            style={{ whiteSpace: "nowrap" }}
                            className="card-title"
                          >
                            Teklif
                          </h5>
                        </div>
                        <span className="text-muted col-lg-ms-1">
                          $1,485,000
                        </span>
                      </div>
                    </span>
                  </h2>
                  <div
                    id="flush-collapseThere"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingThere"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body px-0">
                      <div className="card-body p-3 d-flex">
                        <div className="me-2">
                          <div
                            className="profile"
                            style={{ padding: "10px" }}
                          />
                        </div>
                        <div>
                          <h5>Hatice Durukan</h5>
                          <div className="d-flex flex-column">
                            <span className="text-muted">weboost.dev</span>
                            <span className="text-muted">70.000TL</span>
                            <p className="text-muted">
                              Yarın: Proje müdürünü ara
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card-body p-3 d-flex">
                        <div className="me-2">
                          <div
                            className="profile"
                            style={{ padding: "10px" }}
                          />
                        </div>
                        <div>
                          <h5>Hatice Durukan</h5>
                          <div className="d-flex flex-column">
                            <span className="text-muted">weboost.dev</span>
                            <span className="text-muted">70.000TL</span>
                            <p className="text-muted">
                              Yarın: Proje müdürünü ara
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="card accordion-item p-3 mt-2 "
                style={{ width: "18rem" }}
              >
                <div className="">
                  <h2 className="accordion-header" id="flush-headingFour">
                    <span
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFour"
                      aria-expanded="false"
                      aria-controls="flush-collapseFour"
                    >
                      <div>
                        <div className="">
                          <span className="rounded-circle">10</span>
                        </div>
                        <div className="badges">
                          <h5
                            style={{ whiteSpace: "nowrap" }}
                            className="card-title"
                          >
                            Pazarlık
                          </h5>
                        </div>
                        <span className="text-muted col-lg-ms-1">
                          $1,485,000
                        </span>
                      </div>
                    </span>
                  </h2>
                  <div
                    id="flush-collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingFour"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body px-0">
                      <div className="card-body p-3 d-flex">
                        <div className="me-2">
                          <div
                            className="profile"
                            style={{ padding: "10px" }}
                          />
                        </div>
                        <div>
                          <h5>Hatice Durukan</h5>
                          <div className="d-flex flex-column">
                            <span className="text-muted">weboost.dev</span>
                            <span className="text-muted">70.000TL</span>
                            <p className="text-muted">
                              Yarın: Proje müdürünü ara
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card-body p-3 d-flex">
                        <div className="me-2">
                          <div
                            className="profile"
                            style={{ padding: "10px" }}
                          />
                        </div>
                        <div>
                          <h5>Hatice Durukan</h5>
                          <div className="d-flex flex-column">
                            <span className="text-muted">weboost.dev</span>
                            <span className="text-muted">70.000TL</span>
                            <p className="text-muted">
                              Yarın: Proje müdürünü ara
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
