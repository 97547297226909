import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";

const initialState = {
  contacts: null,
  loading: false,
  editContactsData: null,
};
// delete işlmeleri

// edit için get işlemi
const getContacts = createAsyncThunk("contact/getContacts", async (id) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_KEY}basic/web/index.php/contacts/${id}`
  );
  return response.data;
});
// edit işlemleri put işlemi
const putContacts = createAsyncThunk("contact/putContacts", async (values) => {
  const response = await axios.put(
    `${process.env.REACT_APP_API_KEY}basic/web/index.php/contacts/${values.id}`,
    values
  );
  return response.data;
}); // edit işlemleri son

// Data cekme işlemi
const fetchContacts = createAsyncThunk("contact/fetchContacts", async () => {
  const response = await axios(
    `${process.env.REACT_APP_API_KEY}basic/web/index.php/contacts`
  );
  return response.data;
});
// Data ekleme işlemi Son

// Delete işlemi
const deleteContact = createAsyncThunk("contact/deleteContact", async (id) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_API_KEY}basic/web/index.php/contacts/${id}`
  );
  return response.data;
});

export const ContactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    add: (state, action) => {
      state.contacts = {
        ...action.payload,
      };
    },
    editContacts: (state, action) => {
      state.editContactsData[action.payload.name] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContacts.fulfilled, (state, action) => {
        // Add user to the state array
        state.contacts = action.payload;
      })
      .addCase(fetchContacts.pending, (state, action) => {
        state.contacts = action.payload;
        // loading yapalım
        state.loading = true;
      })
      .addCase(fetchContacts.rejected, (state, action) => {
        state.contacts = action.payload;
        // hatayı handle et
        state.loading = false;
        toast.error("Contacts Not Fount");
      });
    /* delete builder*/
    builder.addCase(deleteContact.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      state.contacts = action.payload;
      toast.success("Delete Success");
    });
    builder.addCase(deleteContact.pending, (state, action) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(deleteContact.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      toast.error("Delete UnSuccess");
    });
    // delete builder son
    // get builder
    builder.addCase(getContacts.fulfilled, (state, action) => {
      state.editContactsData = action.payload;
    });
    builder.addCase(getContacts.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getContacts.rejected, (state, action) => {
      state.loading = false;
      alert("hata");
    });
    // get builder son
    // edit builder
    builder.addCase(putContacts.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      state.contacts = state.contacts.map((contact) => {
        if (contact.id === action.payload.id) {
          return action.payload;
        }
        return contact;
      });
      toast.success("Edited");
    });
    builder.addCase(putContacts.pending, (state, action) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(putContacts.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      toast.error("Edit UnUccess");
    });
  },
});

// Action creators are generated for each case reducer function
export const { add, editContacts } = ContactSlice.actions;
export { fetchContacts, getContacts, putContacts, deleteContact };
export default ContactSlice.reducer;
