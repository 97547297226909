import React from "react";
import Create from "../../clouboard_logo/EtkinlikPages_Iconları/Create.svg";

export default function TeamWorks() {
  return (
    <div className="container mt-5 ">
      <div className="row">
        <table
          style={{ backgroundColor: "#fff", borderRadius: "10px" }}
          className="table"
        >
          <thead style={{ color: "#fd624c" }}>
            <tr>
              <th scope="col">
                <span className="ms-2"> Bu Hafta</span>
              </th>
              <th scope="col">Grup adı </th>
              <th scope="col">Durum</th>
              <th scope="col">Tarih</th>
            </tr>
          </thead>
          <tbody style={{ borderTop: "none" }}>
            <tr>
              <th scope="row ">
                <div className="d-flex flex-row">
                  <div
                    className="my-auto "
                    style={{
                      backgroundColor: "#fd624c",
                      width: "25px",
                      height: "10px",
                    }}
                  />
                  <span className="ms-1 fw-normal"> Görev 1</span>
                </div>
              </th>
              <td className="d-flex">
                <img width={"28px"} src={Create} alt="" />

                <div className="profile" id="profile" />
              </td>
              <td>
                <span
                  style={{
                    padding: "5px",
                    borderRadius: "5px",
                    backgroundColor: "#c8e6c9",
                    color: "#256029",
                  }}
                >
                  Tamamlandı
                </span>
              </td>
              <td>26.05.2022</td>
            </tr>
            <tr>
              <th scope="row">
                <th scope="row ">
                  <div className="d-flex flex-row">
                    <div
                      className="my-auto "
                      style={{
                        backgroundColor: "#fd624c",
                        width: "25px",
                        height: "10px",
                      }}
                    />
                    <span className="ms-1 fw-normal"> Görev 2</span>
                  </div>
                </th>
              </th>
              <td className="d-flex">
                <img width={"28px"} src={Create} alt="" />

                <div className="profile" id="profile" />
              </td>
              <td>
                <span
                  style={{
                    padding: "5px",
                    borderRadius: "5px",
                    backgroundColor: "#ffcdd2",
                    color: "#fc062a",
                  }}
                >
                  Çalışılıyor
                </span>
              </td>
              <td>26.06.2022</td>
            </tr>
            <tr>
              <th scope="row">
                <div className="d-flex flex-row">
                  <div
                    className="my-auto "
                    style={{
                      backgroundColor: "#fd624c",
                      width: "25px",
                      height: "10px",
                    }}
                  />
                  <span className="ms-1 fw-normal"> Görev 3</span>
                </div>
              </th>
              <td className="d-flex">
                <img width={"28px"} src={Create} alt="" />

                <div className="profile" id="profile" />
              </td>
              <td>
                <span
                  style={{
                    padding: "5px",
                    borderRadius: "5px",
                    backgroundColor: "#c8e6c9",
                    color: "#256029",
                  }}
                >
                  Tamamlandı
                </span>
              </td>
              <td>26.05.2022</td>
            </tr>

            {/* Görev ekle */}
            <tr style={{ width: "100%" }}>
              <div className="text-secondary">+ Görev ekle..</div>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="row">
        <table
          style={{ backgroundColor: "#fff", borderRadius: "10px" }}
          className="table"
        >
          <thead>
            <tr style={{ color: "#0092ff" }}>
              <th scope="col">
                <span className="ms-2"> Bu Hafta</span>
              </th>
              <th scope="col">Grup adı </th>
              <th scope="col">Durum</th>
              <th scope="col">Tarih</th>
            </tr>
          </thead>
          <tbody style={{ borderTop: "none" }}>
            <tr>
              <th scope="row ">
                <div className="d-flex flex-row">
                  <div
                    className="my-auto "
                    style={{
                      backgroundColor: "#0092ff",
                      width: "25px",
                      height: "10px",
                    }}
                  />
                  <span className="ms-1 fw-normal"> Görev 1</span>
                </div>
              </th>
              <td className="d-flex">
                <img width={"28px"} src={Create} alt="" />

                <div className="profile" id="profile" />
              </td>
              <td>
                <span
                  style={{
                    padding: "5px",
                    borderRadius: "5px",
                    backgroundColor: "#c8e6c9",
                    color: "#256029",
                  }}
                >
                  Tamamlandı
                </span>
              </td>
              <td>26.05.2022</td>
            </tr>
            <tr>
              <th scope="row">
                <th scope="row ">
                  <div className="d-flex flex-row">
                    <div
                      className="my-auto "
                      style={{
                        backgroundColor: "#0092ff",
                        width: "25px",
                        height: "10px",
                      }}
                    />
                    <span className="ms-1 fw-normal"> Görev 2</span>
                  </div>
                </th>
              </th>
              <td className="d-flex">
                <img width={"28px"} src={Create} alt="" />

                <div className="profile" id="profile" />
              </td>
              <td>
                <span
                  style={{
                    padding: "5px",
                    borderRadius: "5px",
                    backgroundColor: "#ffcdd2",
                    color: "#fc062a",
                  }}
                >
                  Çalışılıyor
                </span>
              </td>
              <td>26.06.2022</td>
            </tr>
            <tr>
              <th scope="row">
                <div className="d-flex flex-row">
                  <div
                    className="my-auto "
                    style={{
                      backgroundColor: "#0092ff",
                      width: "25px",
                      height: "10px",
                    }}
                  />
                  <span className="ms-1 fw-normal">Görev 3</span>
                </div>
              </th>
              <td className="d-flex">
                <img width={"28px"} src={Create} alt="" />

                <div className="profile" id="profile" />
              </td>
              <td>
                <span
                  style={{
                    padding: "5px",
                    borderRadius: "5px",
                    backgroundColor: "#c8e6c9",
                    color: "#256029",
                  }}
                >
                  Tamamlandı
                </span>
              </td>
              <td>26.05.2022</td>
            </tr>

            {/* Görev ekle */}
            <tr style={{ width: "100%" }}>
              <div className="text-secondary">+ Görev ekle..</div>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
