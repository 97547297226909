import React from "react";

import Atach from "../../clouboard_logo/EtkinlikPages_Iconları/Atach.svg";
import Star from "../../clouboard_logo/EtkinlikPages_Iconları/Star.svg";
import Filter from "../../clouboard_logo/EtkinlikPages_Iconları/Filter.svg";
import Edit from "../../clouboard_logo/EtkinlikPages_Iconları/Edit.svg";
import Search from "../../clouboard_logo/EtkinlikPages_Iconları/Search.svg";
import Clean from "./Clean.svg";
import ArrowDown from "./ArrowDown.svg";
import MyWorks from "./MyWorks/MyWorks";

export default function EtkinlikLeftDisplay() {
  return (
    <div className="col mt-5">
      <div className="d-flex px-0 ms-4">
        <h4
          className="fw-bold my-auto	d-none d-sm-inline"
          style={{ whiteSpace: "nowrap", color: "#323441" }}
        >
          Benim Çalışmalarım
        </h4>
        <div className="col d-flex  my-auto ms-2">
          <div style={{ whiteSpace: "nowrap" }} className="col  my-auto">
            <img width={"28px"} className="mt-2 p-1" src={Atach} alt="" />
            <img width={"28px"} className="mt-2 p-1" src={Star} alt="Stars" />
            <img width={"28px"} className="mt-2 p-1" src={Clean} alt="Clean" />
          </div>
          <div style={{ whiteSpace: "nowrap" }} className="col text-end me-4 ">
            <img
              width={"28px"}
              className="mt-2"
              src={ArrowDown}
              alt="ArrowDown"
            />
            <img width={"28px"} className="mt-2" src={Filter} alt="Filter" />
            <img width={"28px"} className="mt-2 p-1" src={Edit} alt="Edit" />
            <img
              width={"28px"}
              className="mt-2 	d-none d-sm-inline"
              src={Search}
              alt="Search"
            />
          </div>
        </div>
      </div>

      <div className="container mt-5 ">
        <MyWorks />
      </div>
    </div>
  );
}
