import * as React from "react";

import { styled } from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";

export default function UserÇalışanYetkileri() {
  const CssTextField = styled(TextField)({
    "& label": {
      // eğer laellerı focus olmadan ortalamak ıstersek
      width: "90%",
      textAlign: "center",
      marginTop: "3.5px",
    },
    /*Settings Son */
    "& label.Mui-focused": {
      color: "#fd624c",

      textAlign: "center",
      transformOrigin: "center",
      "&.Mui-focused": {
        transformOrigin: "center",
        marginLeft: "0 auto",
      },
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fd624c",
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fd624c",
      },
      "&:hover fieldset": {
        borderColor: "#fd624c",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fd624c",
        textAlign: "center",
      },
    },
  });

  return (
    <>
      {/* <Stack
        spacing={3}
        sx={{
          minWidth: 200,
        }}
      > */}
      <h4 className="ms-1">User Çalışan Yetkileri</h4>
      <Autocomplete
        className="mx-1 px-0 mt-3 col-12 col-lg-12 my-2 p-1"
        multiple
        id="tags-outlined"
        options={top100Films}
        getOptionLabel={(option) => option.title}
        defaultValue={[top100Films[1]]}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            required
            sx={{
              width: "98%",
            }}
            {...params}
            label="Genel"
            placeholder="Favorites"
          />
        )}
      />
      <Autocomplete
        className="mx-1 px-0 mt-3 col-12 col-lg-12 my-2 p-1"
        multiple
        id="tags-outlined"
        options={top100Films}
        getOptionLabel={(option) => option.title}
        defaultValue={[top100Films[1]]}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            sx={{
              width: "98%",
            }}
            {...params}
            label="Other"
            placeholder="Favorites"
          />
        )}
      />
      {/* </Stack> */}
      <div className="d-flex  mt-4 flex-column flex-lg-row">
        <div className=" d-flex  flex-column">
          <Autocomplete
            className="mx-1 px-0 mt-3 col-12 col-lg-5 my-2 p-1"
            multiple
            id="tags-outlined"
            options={top100Films}
            getOptionLabel={(option) => option.title}
            defaultValue={[top100Films[0]]}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                sx={{
                  minWidth: 200,
                }}
                required
                {...params}
                label="Firmalar"
                placeholder="Firmalar"
              />
            )}
          />
          <Autocomplete
            className="mx-1 px-0 mt-3 col-12 col-lg-5 my-2 p-1"
            multiple
            id="tags-outlined"
            options={top100Films}
            getOptionLabel={(option) => option.title}
            defaultValue={[top100Films[0]]}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                sx={{
                  minWidth: 200,
                }}
                required
                {...params}
                label="Şikayetler"
                placeholder="Şikayetler"
              />
            )}
          />
          <CssTextField
            sx={{
              minWidth: 200,
            }}
            className="mt-3 col-12 col-lg-4 my-2 ms-1 "
            id="outlined-basic"
            label="Dökümanlar"
            variant="outlined"
          />
          <CssTextField
            sx={{
              minWidth: 200,
            }}
            className="mt-3 ms-1 col-12 col-lg-4 my-2 "
            id="outlined-basic"
            label="Satış Siparişleri"
            variant="outlined"
          />
          <CssTextField
            sx={{
              minWidth: 200,
            }}
            className="mt-3 ms-1 col-12 col-lg-4 my-2 "
            id="outlined-basic"
            label="Ana Ürünler"
            variant="outlined"
          />
          <CssTextField
            sx={{
              minWidth: 200,
            }}
            className="mt-3 ms-1 col-12 col-lg-4 my-2 "
            id="outlined-basic"
            label="Satış Fiyat Listeleri"
            variant="outlined"
          />
          <CssTextField
            sx={{
              minWidth: 200,
            }}
            className="mt-3 ms-1 col-12 col-lg-4 my-2 "
            id="outlined-basic"
            label="Kargo Firmaları"
            variant="outlined"
          />
        </div>
        <div className=" d-flex  flex-column">
          <Autocomplete
            className="mx-1 px-0 mt-3 col-12 col-lg-5 my-2 p-1"
            multiple
            id="tags-outlined"
            options={top100Films}
            getOptionLabel={(option) => option.title}
            defaultValue={[top100Films[0]]}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                sx={{
                  minWidth: 200,
                }}
                required
                {...params}
                label="Firma Adresleri"
                placeholder="Firma Adresleri"
              />
            )}
          />
          <Autocomplete
            className="mx-1 px-0 mt-3 col-12 col-lg-5 my-2 p-1"
            multiple
            id="tags-outlined"
            options={top100Films}
            getOptionLabel={(option) => option.title}
            defaultValue={[top100Films[0]]}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                sx={{
                  minWidth: 200,
                }}
                required
                {...params}
                label="Aktiviteler"
                placeholder="Aktiviteler"
              />
            )}
          />
          <CssTextField
            sx={{
              minWidth: 200,
            }}
            className="mt-3 ms-1 col-12 col-lg-4 my-2 "
            id="outlined-basic"
            label="Takvimler"
            variant="outlined"
          />
          <CssTextField
            sx={{
              minWidth: 200,
            }}
            className="mt-3 ms-1 col-12 col-lg-4 my-2 "
            id="outlined-basic"
            label="Alış Siparişleri"
            variant="outlined"
          />
          <CssTextField
            sx={{
              minWidth: 200,
            }}
            className="mt-3 ms-1 col-12 col-lg-4 my-2 "
            id="outlined-basic"
            label="Ürünler"
            variant="outlined"
          />
          <CssTextField
            sx={{
              minWidth: 200,
            }}
            className="mt-3 ms-1 col-12 col-lg-4 my-2 "
            id="outlined-basic"
            label="Alış Fiyat Listeleri"
            variant="outlined"
          />
          <CssTextField
            sx={{
              minWidth: 200,
            }}
            className="mt-3 ms-1 col-12 col-lg-4 my-2 "
            id="outlined-basic"
            label="Giden Kargolar"
            variant="outlined"
          />
        </div>
        <div className=" d-flex  flex-column">
          <Autocomplete
            className="mx-1 px-0 mt-3 col-12 col-lg-5 my-2 p-1"
            multiple
            id="tags-outlined"
            options={top100Films}
            getOptionLabel={(option) => option.title}
            defaultValue={[top100Films[0]]}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                sx={{
                  minWidth: 200,
                }}
                required
                {...params}
                label="Kontaklar"
                placeholder="Kontaklar"
              />
            )}
          />
          <Autocomplete
            className="mx-1 px-0 mt-3 col-12 col-lg-5 my-2 p-1"
            multiple
            id="tags-outlined"
            options={top100Films}
            getOptionLabel={(option) => option.title}
            defaultValue={[top100Films[0]]}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                sx={{
                  minWidth: 200,
                }}
                required
                {...params}
                label="Görevler"
                placeholder="Görevler"
              />
            )}
          />
          <CssTextField
            sx={{
              minWidth: 200,
            }}
            className="mt-3 ms-1 col-12 col-lg-4 my-2 "
            id="outlined-basic"
            label="Teklifler"
            variant="outlined"
          />
          <CssTextField
            sx={{
              minWidth: 200,
            }}
            className="mt-3 ms-1 col-12 col-lg-4 my-2 "
            id="outlined-basic"
            label="Stok Haraketleri"
            variant="outlined"
          />
          <CssTextField
            sx={{
              minWidth: 200,
            }}
            className="mt-3 ms-1 col-12 col-lg-4 my-2 "
            id="outlined-basic"
            label="Çalışanlar"
            variant="outlined"
          />
          <CssTextField
            sx={{
              minWidth: 200,
            }}
            className="mt-3 ms-1 col-12 col-lg-4 my-2 "
            id="outlined-basic"
            label="E-Postlar"
            variant="outlined"
          />
          <CssTextField
            sx={{
              minWidth: 200,
            }}
            className="mt-3 ms-1 col-12 col-lg-4 my-2 "
            id="outlined-basic"
            label="Gelen Kargolar"
            variant="outlined"
          />
        </div>
      </div>
    </>
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
  { title: "Deneme", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
  { title: "12 Angry Men", year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: "Pulp Fiction", year: 1994 },
];
