import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";
import "../../styles/ContactsAdd.css";
import { styled } from "@mui/material/styles";

export default function UserMasterData() {
  const CssTextField = styled(TextField)({
    "& label": {
      // eğer laellerı focus olmadan ortalamak ıstersek
      width: "90%",
      textAlign: "center",
      marginTop: "3.5px",
    },
    /*Settings Son */
    "& label.Mui-focused": {
      color: "#fd624c",

      textAlign: "center",
      transformOrigin: "center",
      "&.Mui-focused": {
        transformOrigin: "center",
        marginLeft: "0 auto",
      },
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fd624c",
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fd624c",
      },
      "&:hover fieldset": {
        borderColor: "#fd624c",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fd624c",
        textAlign: "center",
      },
    },
  });

  return (
    <div>
      <h4 className="ms-1">Master Data</h4>
      <div className=" ">
        <div className="d-flex flex-column col-lg-mx-auto flex-lg-row  mt-3 ">
          <div className="d-flex flex-column  p-0  mt-3  ">
            <CssTextField
              required
              className=" col-12 col-lg-4 my-2 p-1"
              sx={{
                minWidth: 200,
              }}
              variant="outlined"
              type="email"
              label="Email"
              id="custom-css-outlined-input"
            />
            <CssTextField
              className="  col-12 col-lg-4  my-2  p-1"
              sx={{
                minWidth: 200,
              }}
              variant="outlined"
              label="Password"
              type={"password"}
              id="custom-css-outlined-input"
            />
          </div>
          <FormControl
            className=" col-12 col-lg-4 mt-4 my-2 p-1"
            sx={{ width: 200 }}
          >
            <InputLabel sx={{ color: "#fd624c" }} id="demo-select-small">
              Durum
            </InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select"
              // value={"age"}
              label="Durum"
              // onChange={handleChange}
            >
              <MenuItem
                sx={{
                  color: "#fd624c",
                }}
                value=""
              >
                <em>None</em>
              </MenuItem>
              <MenuItem
                sx={{
                  color: "#fd624c",
                }}
                value={10}
              >
                Ten
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <hr className="mt-2" />
    </div>
  );
}
