import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";
const loginUser = createAsyncThunk(
  "auth/loginUser",
  async ({ email, hashedPassword }) => {
    const response = await axios(
      `${process.env.REACT_APP_API_KEY}basic/web/index.php/users/verify_user?email=${email}&access_token=${hashedPassword}`
    );
    //eğer kullanıcı mevcut değilse Hata verdırdık  throw new Error() sayesınde
    if (response.data.length === 0) throw new Error();

    return response.data;
  }
);

const initialState = {
  user: null,
  loading: false,
  error: false,
  errorMessage: null,
};
if (localStorage.getItem("user")) {
  // initialState.user içine localdekı kayıtlı userı ekledık
  initialState.user = JSON.parse(localStorage.getItem("user"));
}

export const userSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      localStorage.removeItem("user");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.fulfilled, (state, action) => {
        state.user = action.payload[0];
        state.loading = false;
        state.error = false;
        state.errorMessage = null;
        //direkt localstroage ekledik
        localStorage.setItem("user", JSON.stringify(action.payload[0]));
        toast.success("Login Success");
      })
      .addCase(loginUser.pending, (state, action) => {
        state.user = action.payload;
        state.loading = true;
        state.error = false;
        state.errorMessage = null;
      })

      .addCase(loginUser.rejected, (state, action) => {
        // buraya onun için de throw new Error diyoruz
        state.user = action.payload;
        state.loading = false;
        state.error = true;
        state.errorMessage = "Kullanıcı adı veya şifre yanlış";
        toast.error(state.errorMessage);
      });
  },
});

export const { logout } = userSlice.actions;
export { loginUser };
export default userSlice.reducer;
