import React from "react";
import { useDispatch } from "react-redux";

import { logout } from "../Features/authSlice";

export default function NavProfile() {
  const dispatch = useDispatch();
  return (
    <>
      <div className=" dropstart">
        <div
          data-bs-toggle="dropdown"
          aria-expanded="false"
          className="rounded-circle py-1 px-2 dropdown-toggle"
        />
        <ul className="dropdown-menu">
          <li>
            <button
              className="btn  transparent"
              onClick={() => dispatch(logout())}
            >
              Logout
            </button>
          </li>
          <li>{/* New Options */}</li>
        </ul>
      </div>
    </>
  );
}
