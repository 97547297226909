import React from "react";
import { VscClose } from "react-icons/vsc";
import Edit from "../ContactsAdd/Edit.svg";
import Delete from "../../clouboard_logo/Delete.svg";
import Galeri from "../../clouboard_logo/Galeri.svg";
import Create from "../../clouboard_logo/Create.svg";
import MasterData from "./MasterData";
import "../../styles/ContactsAdd.css";
import { IconButton, Input } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import Camera from "../../clouboard_logo/Camera.svg";
import Communication from "./Communication";
import Additional from "./Additional";
import MoreContacts from "./MoreContacts";

export default function ContactAdd({ page }) {
  const Input = styled("input")({
    display: "none",
  });
  return (
    <div className="container">
      <div className="row">
        <span
          className="ms-2 d-block plus"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          {page === "Contacts" && (
            <img width={"30px"} className="mt-2 " src={Create} alt="" />
          )}
        </span>
        <div
          className="modal mt-4 fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content plusRadius ">
              <div className="modal-body justif-content-center d-flex flex-column">
                <div className="d-flex">
                  <h5
                    className="modal-title mx-auto   "
                    id="staticBackdropLabel"
                  >
                    <img width={"30px"} src={Edit} alt="" /> Add Contact
                  </h5>
                  <button
                    style={{
                      backgroundColor: "#fd624c",
                      color: "#fff",
                      fontWeight: "bold",
                      outline: "",
                    }}
                    className="btn text-end"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <VscClose />
                  </button>
                </div>
                <div className="container justfy-content-lg-center text-align-center d-flex flex-column flex-lg-row mt-5 ">
                  <div className="col-2 mx-auto">
                    <label
                      htmlFor="icon-button-file"
                      className="rounded mx-auto"
                      style={{
                        backgroundColor: "#e4e9ef",
                        height: "90px",
                        width: "85px",
                      }}
                    >
                      <Input
                        accept="image/*"
                        id="icon-button-file"
                        type="file"
                      />
                      <IconButton aria-label="upload picture" component="span">
                        <img
                          src={Camera}
                          className="m-2"
                          width="45px"
                          alt="Camera"
                        />
                      </IconButton>
                    </label>
                    <div className="ms-3">
                      <img src={Galeri} width="25px" alt="Galeri" />
                      <img
                        src={Delete}
                        width="25px"
                        className="ms-2"
                        alt="Delete"
                      />
                    </div>
                  </div>
                  <div className="col-10 mx-auto">
                    <form>
                      <MasterData />
                      <Communication />
                      <Additional />
                      <MoreContacts />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
