import React, { useEffect, useState } from "react";
import ContactsCommentModal from "../Modal/ContactsCommentModal";
import "../styles/Contacts.css";
import Onay from "../clouboard_logo/Onay.png";
import Reddedildi from "../clouboard_logo/Reddedildi.png";
/*-Muı-*/
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

/*-icons-*/
import { AiOutlineStar } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { AiOutlinePlusCircle, AiOutlineComment } from "react-icons/ai";

/*---*/

import { useSelector, useDispatch } from "react-redux";
/* Sliceler */
import {
  fetchContacts,
  editContacts,
  putContacts,
  deleteContact,
} from "../Features/ContactSlice";
import { getContacts } from "../Features/ContactSlice";
// Comment Slice
import { fetchComment, postComment } from "../Features/CommentSlice";
const useStyles = makeStyles({
  table: {
    minWidth: 750,
  },
  sticky: {
    position: "sticky",
    left: 0,
    background: "white",
    boxShadow: "5px 2px 5px grey",
    borderRight: "1px solid lightgrey",
  },
  tableCell: {
    color: " #fd624c",
    fontWeight: "bold",
    fontSize: "1.1rem",
  },
});
/* */
export default function Contacts() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const Inputs = [
    {
      name: "contact_number",
      type: "text",
    },
    {
      name: "type",
      type: "text",
    },
    {
      name: "company_name",
      type: "text",
    },
    {
      name: "title_formula",
      type: "text",
    },
    {
      name: "title",
      type: "text",
    },
    {
      name: "firstname",
      type: "text",
    },
    {
      name: "surname",
      type: "text",
    },
    {
      name: "address",
      type: "text",
    },
    {
      name: "zip",
      type: "text",
    },
    {
      name: "city",
      type: "text",
    },
    {
      name: "state",
      type: "text",
    },
    {
      name: "country",
      type: "text",
    },
    {
      name: "email1",
      type: "text",
    },
    {
      name: "email2",
      type: "text",
    },
    {
      name: "phone1",
      type: "text",
    },
    {
      name: "phone2",
      type: "text",
    },
    {
      name: "mobile",
      type: "text",
    },
    {
      name: "fax",
      type: "text",
    },
    {
      name: "website",
      type: "text",
    },
    {
      name: "skype",
      type: "text",
    },
    {
      name: "contact_with",
      type: "text",
    },
    {
      name: "make_from",
      type: "text",
    },
    {
      name: "contact_through",
      type: "text",
    },
    {
      name: "language",
      type: "text",
    },
    {
      name: "note",
      type: "text",
    },
    {
      name: "categories",
      type: "text",
    },
    {
      name: "sectors",
      type: "text",
    },
    {
      name: "number_of_collaborators",
      type: "text",
    },
    {
      name: "registry_number",
      type: "text",
    },
    {
      name: "vat_number",
      type: "text",
    },
    {
      name: "vat_number_intra",
      type: "text",
    },
    {
      name: "formula_of_greeting",
      type: "text",
    },
    {
      name: "birthday",
      type: "text",
    },
    {
      name: "parent_id",
      type: "text",
    },
    {
      name: "created_by",
      type: "text",
    },
    {
      name: "updated_by",
      type: "text",
    },
    {
      name: "created_date",
      type: "text",
    },
    {
      name: "updated_date",
      type: "text",
    },
  ];
  const { contacts, editContactsData } = useSelector((state) => state.contacts);

  const [values, setValues] = useState({
    contact_number: "",
    type: "",
    company_name: "",
    title_formula: "",
    title: "",
    firstname: "",
    surname: "",
    address: "",
    zip: "",
    city: "",
    state: "",
    country: "",
    email1: "",
    email2: "",
    phone1: "",
    phone2: "",
    mobile: "",
    fax: "",
    website: "",
    skype: "",
    contact_with: "",
    make_from: "",
    contact_through: "",
    language: "",
    note: "",
    categories: "",
    sectors: "",
    number_of_collaborators: "",
    registry_number: "",
    vat_number: "",
    vat_number_intra: "",
    formula_of_greeting: "",
    birthday: "",
    parent_id: "",
    created_by: "",
    updated_by: "",
    created_date: "",
    updated_date: "",
  });

  useEffect(() => {
    dispatch(fetchContacts());
  }, []);
  const { comment } = useSelector((state) => state.comment);
  // console.log(comment);

  const columns = [
    {
      name: "İsim",
    },
    {
      name: "Şirket",
    },
    {
      name: "kazanılan",
    },
    {
      name: "Durum",
    },
    {
      name: "Aktiflik",
    },
    {
      name: "Doğrulandı",
    },
    {
      name: <AiOutlinePlusCircle />,
    },
  ];
  const customStyles = {
    rows: {
      style: {
        minHeight: "72px", // override the row height
      },
    },
    headCells: {
      style: {
        // color: "#fd624c",
        fontWeight: "bold",
        fontSize: "1.2rem",
        // paddingLeft: "8px", // override the cell padding for head cells
        // paddingRight: "8px",
      },
    },
    cells: {
      style: {
        // paddingLeft: "8px", // override the cell padding for data cells
        // paddingRight: "8px",
      },
    },
  };
  const rows = [
    {
      // kazanılan durum falan bunlar gelen veride yok
      id: 1,
      İsim: "Beetlejuice",
      Şirket: "Weboost",
      kazanılan: "$45454",
      Durum: (
        <div style={{ backgroundColor: "#c8e6c9" }} className="p-1 rounded">
          <span style={{ color: "#256029" }}>Tamamlandı</span>
        </div>
      ),
      Aktiflik: (
        <div
          className="progress"
          style={{
            height: "5px",
          }}
        >
          <div
            className="progress-bar"
            role="progressbar"
            style={{
              backgroundColor: "#fd624c",
              width: "100%",
              height: "5px",
            }}
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          />
        </div>
      ),
      Doğrulandı: <img style={{ width: "30px" }} src={Onay} alt="Onay" />,
      Comment: "Details",
    },
    {
      id: 2,
      İsim: "james",
      Şirket: "Clouboard",
      kazanılan: "$145450",
      Durum: (
        <div style={{ backgroundColor: "#ffcdd2" }} className="p-1 rounded">
          {" "}
          <span style={{ color: "#fc062a" }}>Yetersiz</span>
        </div>
      ),
      Aktiflik: (
        <div
          className="progress"
          style={{
            height: "5px",
          }}
        >
          <div
            className="progress-bar"
            role="progressbar"
            style={{
              backgroundColor: "#fd624c",
              width: "80%",
              height: "5px",
            }}
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          />
        </div>
      ),
      Doğrulandı: <img style={{ width: "30px" }} src={Reddedildi} alt="Onay" />,
      Comment: "Details",
    },
    {
      id: 3,
      İsim: "John",
      Şirket: "Weboost",
      kazanılan: "$7000",
      Durum: (
        <div style={{ backgroundColor: "#b3e5fc" }} className="p-1 rounded">
          <span style={{ color: "#0092ff" }}>Yeni</span>
        </div>
      ),
      Aktiflik: (
        <div
          className="progress"
          style={{
            height: "5px",
          }}
        >
          <div
            className="progress-bar"
            role="progressbar"
            style={{
              backgroundColor: "#fd624c",
              width: "10%",
              height: "5px",
            }}
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          />
        </div>
      ),
      Doğrulandı: (
        <div
          style={{ width: "15px", height: "15px" }}
          className="spinner-border text-warning"
          role="status"
        >
          <span
            style={{ width: "15px", height: "15px" }}
            className="visually-hidden"
          >
            Loading...
          </span>
        </div>
      ),
      Comment: "Details",
    },
    {
      id: 4,
      İsim: "jack",
      Şirket: "Clouboard",
      kazanılan: "$122",
      Durum: (
        <div style={{ backgroundColor: "#ffd8b2" }} className="p-1 rounded">
          {" "}
          <span style={{ color: "#fd624c" }}>Teklif</span>
        </div>
      ),
      Aktiflik: (
        <div
          className="progress"
          style={{
            height: "5px",
          }}
        >
          <div
            className="progress-bar"
            role="progressbar"
            style={{
              backgroundColor: "#fd624c",
              width: "50%",
              height: "5px",
            }}
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          />
        </div>
      ),
      Doğrulandı: (
        <div
          style={{ width: "15px", height: "15px" }}
          className="spinner-border"
          role="status"
        >
          <span
            style={{ width: "15px", height: "15px" }}
            className="visually-hidden"
          >
            Loading...
          </span>
        </div>
      ),
      Comment: "Details",
    },
  ];

  return (
    <>
      <div className="Contacts">
        <div className="container">
          <div className="row d-flex">
            <div className="col  col-lg-12 py-5 my-5 d-flex justify-content-center text-align-center">
              <TableContainer
                style={{
                  maxWidth: 800,
                  borderRadius: "32px",
                  boxShadow: "5px 5px 5px 5px lightgrey",
                }}
              >
                <Table
                  className={classes.table}
                  aria-label="simple table"
                  style={{ tableLayout: "fixed", zIndex: "1" }}
                >
                  <TableHead>
                    <TableRow>
                      {columns.map((col, idx) => (
                        <TableCell
                          key={idx}
                          align="left"
                          className={classes.sticky + " " + classes.tableCell}
                        >
                          {col.name}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {contacts &&
                      contacts.map((contact) => (
                        <TableRow key={contact.id}>
                          <TableCell
                            className={classes.sticky}
                            component="th"
                            scope="row"
                          >
                            {`${contact.firstname} ${contact.surname}`}
                          </TableCell>
                          <TableCell
                            className={classes.sticky}
                            component="th"
                            scope="row"
                          >
                            {`${contact.company_name}`}
                          </TableCell>
                          <TableCell
                            className={classes.sticky}
                            component="th"
                            scope="row"
                          >
                            $45454
                          </TableCell>
                          <TableCell className={classes.sticky}>-</TableCell>
                          <TableCell
                            className={classes.sticky}
                            component="th"
                            scope="row"
                          >
                            <div
                              className="progress"
                              style={{
                                height: "5px",
                              }}
                            >
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                  backgroundColor: "#fd624c",
                                  width: "100%",
                                  height: "5px",
                                }}
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              />
                            </div>
                          </TableCell>
                          <TableCell
                            className={classes.sticky}
                            component="th"
                            scope="row"
                          >
                            -
                          </TableCell>
                          <TableCell
                            className={classes.sticky}
                            component="th"
                            scope="row"
                          >
                            Details
                          </TableCell>
                        </TableRow>
                      ))}
                    {rows.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell
                          className={classes.sticky}
                          component="th"
                          scope="row"
                        >
                          {row.İsim}
                        </TableCell>
                        <TableCell
                          className={classes.sticky}
                          component="th"
                          scope="row"
                        >
                          {row.Şirket}
                        </TableCell>
                        <TableCell
                          className={classes.sticky}
                          component="th"
                          scope="row"
                        >
                          {row.kazanılan}
                        </TableCell>
                        <TableCell
                          className={classes.sticky}
                          component="th"
                          scope="row"
                        >
                          {row.Durum}
                        </TableCell>
                        <TableCell
                          className={classes.sticky}
                          component="th"
                          scope="row"
                        >
                          {row.Aktiflik}
                        </TableCell>
                        <TableCell
                          className={classes.sticky}
                          component="th"
                          scope="row"
                        >
                          {row.Doğrulandı}
                        </TableCell>
                        <TableCell
                          className={classes.sticky}
                          component="th"
                          scope="row"
                        >
                          {row.Comment}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="row ms-1">
                <ul
                  style={{ listStyle: "none" }}
                  className="list-group    mt-5"
                >
                  {contacts &&
                    contacts.map(({ id }, idx) => (
                      <div className="d-flex" key={idx}>
                        <li className="list-group-item p-0 m-0 bg-transparent  border-0 ">
                          <ContactsCommentModal id={id} />
                        </li>
                        <li>
                          <span className="ms-3">
                            <AiOutlineStar />
                          </span>
                        </li>
                        <li>
                          <span
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModals"
                            className="ms-3"
                            onClick={() => {
                              // console.log(id);
                              dispatch(getContacts(id));
                            }}
                          >
                            <FiEdit />
                          </span>
                        </li>
                        <li>
                          <span
                            onClick={() => {
                              dispatch(deleteContact(id));
                            }}
                            className="ms-3"
                          >
                            <RiDeleteBin6Line />
                          </span>
                        </li>
                      </div>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modallar */}
      <div
        className="modal fade"
        id="exampleModals"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Modal title
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  {editContactsData &&
                    Inputs.map((input) => (
                      <div key={input.id}>
                        <label htmlFor={input.id}>{input.name}</label>
                        <input
                          type={input.type}
                          className="form-control"
                          id={input.name}
                          name={input.name}
                          placeholder={input.name}
                          value={editContactsData[input.name] || ""}
                          onChange={(e) => {
                            dispatch(
                              editContacts({
                                name: e.target.name,
                                value: e.target.value,
                              })
                            );
                          }}
                        />
                      </div>
                    ))}
                </div>
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  onClick={() => dispatch(putContacts(editContactsData))}
                  className="btn btn-primary"
                >
                  Edit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
