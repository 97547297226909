import React from "react";
import "./styles/Navbar.css";
export default function App() {
  return (
    <div className="App">
      <div className="row">
        <div className="col">
          <h3 className="text-center mt-5">Welcome to Clouboard</h3>
        </div>
      </div>
    </div>
  );
}
