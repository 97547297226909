import React, { useState } from "react";
import "../styles/Etkinlik.css";
import EtkinlikLeftDisplay from "../components/Etkinlikler/EtkinlikLeftDisplay";
import EtkinlikRightDisplay from "../components/Etkinlikler/EtkinlikRightDisplay";
import { AiOutlineLeft } from "react-icons/ai";
/* width ölcer  */
import { useMediaQuery } from "react-responsive";
export default function Etkinlik() {
  const [hasActive, setHasActive] = useState(false);
  const isTablet = useMediaQuery({ query: "(max-width: 1200px)" });

  // mobile settings   etkinlikLeft =Benim Çalışmalarım etkinlikRight =Grup Çalışmaları

  const [myWork, setMyWork] = useState(false);
  const [teamWork, setTeamWork] = useState(false);

  return (
    <div className="Etkınlık position-relative">
      <div className="container">
        <div className="row ">
          {/* mobile Start */}
          <div className="d-block d-sm-none">
            <ul className="nav d-flex flex-row justify-content-center d-block d-sm-none">
              <li className="nav-item my-auto">
                <a
                  style={{
                    cursor: "pointer",
                    color: "#323441",
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    setTeamWork(!teamWork);
                    setMyWork(!myWork);
                  }}
                  className={`nav-link  ${myWork && "active"}`}
                  aria-current="page"
                >
                  Benim Çalışmalarım
                </a>
              </li>
              <div
                id="Mobilehr"
                style={{
                  border: "1px solid gray",
                }}
              />
              <li className="nav-item my-auto">
                <a
                  style={{
                    cursor: "pointer",
                    color: "#323441  ",

                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    setMyWork(!myWork);
                    setTeamWork(!teamWork);
                  }}
                  className={`nav-link  ${!teamWork && "active"}`}
                  aria-current="page"
                >
                  Group Çalışmalarım
                </a>
              </li>
            </ul>
            <hr className="mt-1 	d-block d-sm-none" />
            {myWork ? <EtkinlikLeftDisplay /> : <EtkinlikRightDisplay />}
          </div>

          {/* mobile son */}

          <div className="adem">
            <div
              className="displayButton my-auto"
              onClick={() => setHasActive(!hasActive)}
              style={{
                position: "absolute",
                top: "2rem",
                left: hasActive ? "unset" : -25,
                right: hasActive ? -25 : "unset",
                width: "50px",
                height: "90px",
                padding: "10px",
                backgroundColor: "#fd624c",
                padding: "10px",
                color: "#fff",
                borderRadius: "50%",
                // borderTopLeftRadius: "100%",
                // borderBottomLeftRadius: "100%",
                transform: hasActive ? "unset" : "rotate(180deg)",
              }}
            >
              <AiOutlineLeft
                style={{
                  position: "absolute",
                  bottom: "40%",
                  right: "25px",
                }}
              />
            </div>
            {!isTablet ? (
              <div className="d-flex">
                <EtkinlikLeftDisplay />
                <EtkinlikRightDisplay />
              </div>
            ) : hasActive ? (
              <EtkinlikRightDisplay />
            ) : (
              <EtkinlikLeftDisplay />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
