import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import axios from "axios";

const fetchComment = createAsyncThunk("comment/fetchComment", async (id) => {
  const response = await axios(
    `https://cors-anywhere.herokuapp.com/${process.env.REACT_APP_API_KEY}basic/web/index.php/comments/get_customercomments?customer_id=${id}`
  );
  return response.data;
});

const deleteComment = createAsyncThunk("comment/deleteComment", async (id) => {
  const response = await axios.delete(
    // `https://cors-anywhere.herokuapp.com/${process.env.REACT_APP_API_KEY}basic/web/index.php/comments/get_customercomments?customer_id=${id}`
    `https://cors-anywhere.herokuapp.com/${process.env.REACT_APP_API_KEY}basic/web/index.php/comments/${id}`
  );

  return id;
});
// Edit Comment
const getComment = createAsyncThunk("comment/getComment", async (id) => {
  const response = await axios.get(
    // `https://cors-anywhere.herokuapp.com/${process.env.REACT_APP_API_KEY}basic/web/index.php/comments/get_customercomments?customer_id=${id}`
    `https://cors-anywhere.herokuapp.com/${process.env.REACT_APP_API_KEY}basic/web/index.php/comments/${id}`
  );
  console.log("response.data: ", response.data);

  return response.data;
});
// put Comment
const editComment = createAsyncThunk(
  "comment/editComment",
  async (editCommentData) => {
    const response = await axios.put(
      `https://cors-anywhere.herokuapp.com/${process.env.REACT_APP_API_KEY}basic/web/index.php/comments/${editCommentData.id}`,
      editCommentData
    );
    return editCommentData;
  }
);
/* postComment */
const postComment = createAsyncThunk(
  "comment/postComment",
  async (editcommentValues) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_KEY}basic/web/index.php/comments`,
      editcommentValues
    );
    return editcommentValues;
  }
);

const initialState = {
  loading: false,
  comment: null,
  editCommentData: null,
  error: null,
};

export const commentSlice = createSlice({
  name: "comment",
  initialState,
  reducers: {
    add: (state, action) => {
      state.comment = action.payload;
    },
    setCommentData: (state, action) => {
      console.log("action_payload_name: ", action.payload.name);
      // state.editCommentData[action.payload.name] = action.payload.value;
      state.editCommentData[action.payload.name] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchComment.fulfilled, (state, action) => {
        state.comment = action.payload;
      })
      .addCase(fetchComment.pending, (state) => {
        // loading yapalım
        state.loading = true;
      })
      .addCase(fetchComment.rejected, (state) => {
        // hatayı handle et
        state.loading = false;
      })

      // postComment
      .addCase(postComment.fulfilled, (state, action) => {
        state.comment = [...state.comment, action.payload];
        state.loading = false;
        state.error = false;
        toast.success("Success");
      })
      .addCase(postComment.pending, (state) => {
        // istek atılırken loading
        state.loading = true;
      })
      .addCase(postComment.rejected, (state) => {
        state.error = true;
        state.loading = false;
        toast.success("İşlem Başarısız");
      })
      /* Delete Comment */

      .addCase(deleteComment.fulfilled, (state, action) => {
        state.comment = current(state.comment).filter(
          (comment) => comment.id !== action.payload
        );
        toast.success("success");
      })
      .addCase(deleteComment.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteComment.rejected, (state) => {
        state.loading = false;
        toast.error("İşlem Başarısız");
      })
      /* Edıtıng */
      // get builder
      .addCase(getComment.fulfilled, (state, action) => {
        state.loading = false;
        state.editCommentData = action.payload;
      })
      .addCase(getComment.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getComment.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      /*PUT İŞLEMİ */
      .addCase(editComment.fulfilled, (state, action) => {
        state.loading = false;
        // editlenen elemeanı siliyoruz editlenmeden önceki hali olduğu için
        // let filtered = state.comment.filter((i) => i.id !== action.payload.id);
        // state.comment = [...filtered, action.payload];
        state.comment = state.comment.filter((i) => {
          if (i.id === action.payload.id) {
            return action.payload;
          }
          return i;
        });
        toast.success("Success");
      })
      .addCase(editComment.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(editComment.rejected, (state) => {
        state.loading = false;
        state.error = true;
        toast.error("işlem başarısız");
      });
    /* edit builder PUT İŞLEMİ*/
  },
});

export const { add, setCommentData } = commentSlice.actions;
export { fetchComment, postComment, deleteComment, getComment, editComment };
export default commentSlice.reducer;
