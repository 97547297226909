import React from "react";
import Atach from "../../clouboard_logo/EtkinlikPages_Iconları/Atach.svg";
import Star from "../../clouboard_logo/EtkinlikPages_Iconları/Star.svg";
import Filter from "../../clouboard_logo/EtkinlikPages_Iconları/Filter.svg";
import Edit from "../../clouboard_logo/EtkinlikPages_Iconları/Edit.svg";
import Search from "../../clouboard_logo/EtkinlikPages_Iconları/Search.svg";
import Create from "../../clouboard_logo/EtkinlikPages_Iconları/Create.svg";
import Clean from "./Clean.svg";
import ArrowDown from "./ArrowDown.svg";
import Person from "./Person.svg";

import TeamWorks from "../Etkinlikler/TeamWorks";

export default function EtkinlikRightDisplay() {
  return (
    <div className="col mt-5">
      <div className="d-flex px-0 ms-4">
        <h4
          className="fw-bold my-auto	d-none d-sm-inline"
          style={{ whiteSpace: "nowrap", color: "#323441" }}
        >
          Grup Çalışmaları
        </h4>
        <div className="col d-flex  my-auto ms-2">
          <div style={{ whiteSpace: "nowrap" }} className="col mb-2 my-auto">
            <img
              width={"28px"}
              className="mt-2 p-1 	d-none d-sm-inline"
              src={Create}
              alt=""
            />
            <img
              width={"28px"}
              className="mt-2 p-1 	d-none d-sm-inline"
              src={Person}
              alt=""
            />

            <img width={"28px"} className="mt-2 p-1" src={Atach} alt="" />
            <img width={"28px"} className="mt-2 p-1" src={Star} alt="Stars" />
            <img src={Clean} className="mt-1" style={{ width: "28px" }} />
          </div>
          slm
          <div
            style={{ whiteSpace: "nowrap" }}
            className="col mb-2 ms-5 text-end me-3 px-0"
          >
            <img
              src={ArrowDown}
              className="my-auto"
              style={{ width: "28px" }}
            />
            <img width={"28px"} className="mt-2" src={Filter} alt="Filter" />
            <img width={"28px"} className="mt-2 p-1" src={Edit} alt="Edit" />
            <img
              width={"28px"}
              className="mt-2 d-none d-sm-inline"
              src={Search}
              alt="Search"
            />
          </div>
        </div>
      </div>
      <TeamWorks />
    </div>
  );
}
