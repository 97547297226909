import { TextField } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";

export default function UserCommunication() {
  const CssTextField = styled(TextField)({
    "& label": {
      // eğer laellerı focus olmadan ortalamak ıstersek
      width: "90%",
      textAlign: "center",
      marginTop: "3.5px",
    },
    /*Settings Son */
    "& label.Mui-focused": {
      color: "#fd624c",

      textAlign: "center",
      transformOrigin: "center",
      "&.Mui-focused": {
        transformOrigin: "center",
        marginLeft: "0 auto",
      },
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fd624c",
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fd624c",
      },
      "&:hover fieldset": {
        borderColor: "#fd624c",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fd624c",
        textAlign: "center",
      },
    },
  });
  return (
    <>
      <div className="">
        <h4 className="ms-1">Communication</h4>
        <div className="row ">
          <div className="d-flex flex-column  flex-lg-row">
            <div className=" d-flex flex-column">
              <CssTextField
                required
                sx={{
                  minWidth: 200,
                }}
                className="mt-3 col-12 col-lg-4 my-2 p-1"
                id="outlined-basic"
                label="Name Surname"
                variant="outlined"
              />
              <CssTextField
                sx={{
                  minWidth: 200,
                }}
                className="mt-3 col-12 col-lg-4 my-2 p-1"
                id="outlined-basic"
                label="Unvan"
                variant="outlined"
              />
              <CssTextField
                sx={{
                  minWidth: 200,
                }}
                className="mt-3 col-12 col-lg-4 my-2 p-1"
                id="outlined-basic"
                label="Kimlik No"
                variant="outlined"
              />
              <CssTextField
                sx={{
                  minWidth: 200,
                }}
                className="mt-5 col-12 col-lg-4 my-2 p-1"
                id="outlined-basic"
                label="İşe giriş-çıkış"
                variant="outlined"
              />
            </div>
            <div className="col-12 col-lg-4  p-0">
              <div
                style={{ whiteSpace: "nowrap" }}
                className=" d-flex  flex-lg-row"
              >
                <CssTextField
                  required
                  sx={{
                    minWidth: 200,
                  }}
                  className="mt-3 col-10 col-lg-4 my-2 p-1"
                  id="outlined-basic"
                  label="Telephone 1"
                  variant="outlined"
                />
                <span className="fst-italic fs-6 mt-4 ms-2 text-muted">
                  Home
                </span>
              </div>
              <div
                style={{ whiteSpace: "nowrap" }}
                className=" d-flex  flex-lg-row"
              >
                <CssTextField
                  sx={{
                    minWidth: 200,
                  }}
                  className="mt-3 col-10 col-lg-8 my-2 p-1"
                  id="outlined-basic"
                  label="Telephone 2"
                  variant="outlined"
                />
                <span className="fst-italic fs-6 mt-4 ms-2 text-muted">
                  Work
                </span>
              </div>
              <div
                style={{ whiteSpace: "nowrap" }}
                className=" d-flex  flex-lg-row"
              >
                <CssTextField
                  sx={{
                    minWidth: 200,
                  }}
                  className="mt-3 col-10 col-lg-4 my-2 p-1"
                  id="outlined-basic"
                  label="Telephone 3"
                  variant="outlined"
                />
                <span className="fst-italic fs-6 mt-4 ms-1 text-muted">
                  Mobile
                </span>
              </div>
              <div className=" d-flex  flex-lg-row">
                <CssTextField
                  sx={{
                    minWidth: 300,
                  }}
                  id="outlined-multiline-outlined"
                  multiline
                  rows="4"
                  // value={commentValues.comment}
                  // onChange={handleChange("comment")}
                  className="mt-3 col-12 col-lg-12 my-2 p-1"
                  label="Address..."
                  variant="outlined"
                />
              </div>
            </div>
          </div>
        </div>

        <hr className="mt-2" />
      </div>
    </>
  );
}
