import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./styles/index.css";

import toast, { Toaster } from "react-hot-toast";
import { BrowserRouter, Route, Routes } from "react-router-dom";
//---------------------Pages---------------------
import Haber from "./pages/Haber";
import Satis from "./pages/Satis";
import Raporlar from "./pages/Rapor";
import Etkinlik from "./pages/Etkinlik";
import Contacts from "./pages/Contacts";
import Urun from "./pages/Urun";
import Calendar from "./pages/Calendar";
import Settings from "./pages/Settings.js";
import Login from "./pages/Login.js";
import Navbar from "./components/Navbar";
//---------------------Pages-And-------------------
//---------------------Store-Start-------------------
import { store } from "./store";
import { Provider } from "react-redux";
import ProtectedRoute from "./components/ProtectedRoute";
import Users from "./pages/Users";
//---------------------Store-And-------------------
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Toaster
          toastOptions={{
            duration: 2000,
            position: "top-center",
            // style: {
            //   border: "1px solid #713200",
            //   padding: "16px",
            //   color: "#713200",
            // },
          }}
        />
        <Navbar />
        <Routes>
          <Route
            index
            path="/"
            element={
              <ProtectedRoute>
                <App />
              </ProtectedRoute>
            }
          />

          <Route
            path="/Haber"
            element={
              <ProtectedRoute>
                <Haber />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Satis"
            element={
              <ProtectedRoute>
                <Satis />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Raporlar"
            element={
              <ProtectedRoute>
                <Raporlar />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Etkinlik"
            element={
              <ProtectedRoute>
                <Etkinlik />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Contacts"
            element={
              <ProtectedRoute>
                <Contacts />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Urun"
            element={
              <ProtectedRoute>
                <Urun />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Calendar"
            element={
              <ProtectedRoute>
                <Calendar />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Settings"
            element={
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Users"
            element={
              <ProtectedRoute>
                <Users />
              </ProtectedRoute>
            }
          />
          <Route path="/Login" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
