import Haber from "../clouboard_logo/NavIcons/Haber.svg";
import Satis from "../clouboard_logo/NavIcons/Satıs.svg";
import Raporlar from "../clouboard_logo/NavIcons/Rapor.svg";
import Etkinlik from "../clouboard_logo/NavIcons/Etkınlık.svg";
import Contacts from "../clouboard_logo/NavIcons/Contacts.svg";
import Calendar from "../clouboard_logo/NavIcons/Ajanda.svg";
import Urun from "../clouboard_logo/NavIcons/Urun.svg";
import Settings from "../clouboard_logo/NavIcons/Settings.svg";
import Users from "../clouboard_logo/NavIcons/User.svg";

const pageHeader = {
  Haber,
  Satis,
  Etkinlik,
  Contacts,
  Calendar,
  Urun,
  Settings,
  Raporlar,
  Users,
};

export default pageHeader;
