import { configureStore } from "@reduxjs/toolkit";
import contactsReducer from "./Features/ContactSlice";
import userReducer from "./Features/userSlice";
import authSlice from "./Features/authSlice";
import CommentReducer from "./Features/CommentSlice";

export const store = configureStore({
  reducer: {
    contacts: contactsReducer,
    user: userReducer,
    auth: authSlice,
    comment: CommentReducer,
  },
});
