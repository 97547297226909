import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import "../styles/Navbar.css";

// ------------------İcons-------------------------------
// import { FiArrowUp, FiArrowDown } from "react-icons/fi";

import Haber from "../clouboard_logo/Haber.svg";
import Satıs from "../clouboard_logo/Satıs.svg";
import Rapor from "../clouboard_logo/Rapor.svg";
import Etkınlık from "../clouboard_logo/Etkınlık.svg";
import Contacts from "../clouboard_logo/Contacts.svg";
import Ajanda from "../clouboard_logo/Ajanda.svg";
import Urun from "../clouboard_logo/Urun.svg";
import User from "../clouboard_logo/User.svg";
import Settings from "../clouboard_logo/Settings.svg";
import NavbarLogo from "../clouboard_logo/navbarLogo.ico";

//-------------------------------------------------------
import Nav from "./Nav";
import { useDispatch, useSelector } from "react-redux";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
const DynamicLink = [
  {
    Name: "Haber",
    url: "/Haber",
    img: Haber,
  },
  {
    Name: "Satis",
    url: "/Satis",
    img: Satıs,
    sublinks: [
      { name: "Satış tahminleri", url: "/Satış Tahminleri" },
      { name: "Satış Fırsatları", url: "/Satış Fırsatları" },
      { name: "Faturalar", url: "/Faturalar" },
    ],
  },
  {
    Name: "Raporlar",
    url: "/Raporlar",
    img: Rapor,
  },
  {
    Name: "Etkinlik",
    url: "/Etkinlik",
    img: Etkınlık,
  },
  {
    Name: "Contacts",
    url: "/Contacts",
    img: Contacts,
  },
  {
    Name: "Urun",
    url: "/Urun",
    img: Urun,
  },
  {
    Name: "Calendar",
    url: "/Calendar",
    img: Ajanda,
  },
  {
    Name: "Settings",
    url: "/Settings",
    img: Settings,
  },

  {
    Name: "Users",
    url: "/Users",
    img: User,
  },
];
export default function Navbar() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [isHovering, setIsHovering] = useState(true);
  if (!user) return null;
  return (
    <div>
      <Nav />
      <div
        className="offcanvas d-none d-lg-block   offcanvas-start sidebar-nav"
        tabIndex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header mt-4 ms-3">
          <Link to="/">
            <img width={"200px"} src={NavbarLogo} alt="Clouboard" />
          </Link>
        </div>
        <div className="offcanvas-body ">
          <ul className="navbar-nav  mb-lg-0 ms-4">
            {DynamicLink.map((item, index) => {
              const { Name, url, img, sublinks } = item;

              return (
                <li key={index} className="nav-item mt-4">
                  <NavLink
                    to={url}
                    className={` navText ms-3 link ${({ isActive }) =>
                      isActive && "active"}`}
                  >
                    <img
                      id="img"
                      width={"30px"}
                      src={img}
                      alt={Name}
                      className={` navText ms-3 me-2 active link ${({
                        isActive,
                      }) => isActive && "active"}`}
                    />
                    {Name}
                    {sublinks && (
                      <>
                        <span
                          className="dropdown-togggle text-white ms-5"
                          data-bs-toggle="collapse"
                          href="#collapseExample"
                          role="button"
                          aria-expanded="false"
                          aria-haspopup="true"
                          aria-controls="collapseExample"
                          onClick={() => setIsHovering(!isHovering)}
                        >
                          {isHovering ? <BsChevronDown /> : <BsChevronUp />}
                        </span>

                        <ul className="collapse" id="collapseExample">
                          {sublinks.map((item, idx) => {
                            return (
                              <li key={idx} className="ms-4">
                                <span className="dropdown-item ms-3 nav-link text-white bg-transparent">
                                  {item.name}
                                </span>
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    )}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}
