import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
export default function Additional() {
  const CssTextField = styled(TextField)({
    "& label": {
      // eğer laellerı focus olmadan ortalamak ıstersek
      width: "90%",
      textAlign: "center",
      marginTop: "3.5px",
    },
    /*Settings Son */
    "& label.Mui-focused": {
      color: "#fd624c",

      textAlign: "center",
      transformOrigin: "center",
      "&.Mui-focused": {
        transformOrigin: "center",
        marginLeft: "0 auto",
      },
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fd624c",
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fd624c",
      },
      "&:hover fieldset": {
        borderColor: "#fd624c",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fd624c",
        textAlign: "center",
      },
    },
  });

  return (
    <div className="">
      <h4 style={{ whiteSpace: "break-spaces" }}>Additional Informatıon</h4>
      <div className="mt-2">
        <div className="  d-flex flex-column flex-lg-row">
          <div className="d-flex flex-column ">
            <CssTextField
              required
              sx={{
                minWidth: 200,
              }}
              className="mt-3 col-12 col-lg-4 my-2 p-1"
              id="outlined-basic"
              label="Contact partner"
              variant="outlined"
            />
            <CssTextField
              sx={{
                minWidth: 200,
              }}
              className="mt-3 col-12 col-lg-4 my-2 p-1"
              id="outlined-basic"
              label="Correspendonce type"
              variant="outlined"
            />
            <FormControl
              className="mt-3 col-12 col-lg-4 my-2 p-1"
              sx={{
                minWidth: 200,
              }}
            >
              <InputLabel id="demo-select-small">Team</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select"
                // value={"age"}
                label="Team"
                // onChange={handleChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              className="mt-3 col-12 col-lg-4 my-2 p-1"
              sx={{
                minWidth: 200,
              }}
            >
              <InputLabel id="demo-select-small">Category</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select"
                // value={"age"}
                label="Category"
                // onChange={handleChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              className="mt-3 col-12 col-lg-4 my-2 p-1"
              sx={{
                minWidth: 200,
              }}
            >
              <InputLabel id="demo-select-small">Sector</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select"
                // value={"age"}
                label="Sector"
                // onChange={handleChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className=" d-flex  flex-column">
            <CssTextField
              sx={{
                minWidth: 200,
              }}
              className="mt-3 col-12 col-lg-4 my-2 p-1"
              id="outlined-basic"
              label="Owner*"
              variant="outlined"
            />
            <CssTextField
              sx={{
                minWidth: 200,
              }}
              className="mt-3 col-12 col-lg-4 my-2 p-1"
              id="outlined-basic"
              label="Language"
              variant="outlined"
            />
            {/* <div className="mt-5"> */}
            <div className="col-12">
              <CssTextField
                id="outlined-multiline-outlined"
                multiline
                rows="4"
                // value={commentValues.comment}
                // onChange={handleChange("comment")}
                className="mt-3 col-12 col-lg-12 my-2 p-1"
                label="Reamarks..."
                variant="outlined"
              />

              {/* <TextareaAutosize
                className="my-2"
                variant="outlined"
                id="outlined-textareas"
                aria-label="empty textarea"
                placeholder="Remarks"
                style={{ minHeight: 160 }}
              /> */}
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
      <div className="mt-2">
        <span
          style={{
            backgroundColor: "#e4e9ef",
            marginTop: "10px",
            whiteSpace: "break-spaces",
          }}
          className="rounded ms-1 fs-6 p-2 "
        >
          + Add additional information
        </span>
      </div>
      <hr className="mt-5" />
    </div>
  );
}
