import { TextField } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";

export default function Communication() {
  const CssTextField = styled(TextField)({
    "& label": {
      // eğer laellerı focus olmadan ortalamak ıstersek
      width: "90%",
      textAlign: "center",
      marginTop: "3.5px",
    },
    /*Settings Son */
    "& label.Mui-focused": {
      color: "#fd624c",

      textAlign: "center",
      transformOrigin: "center",
      "&.Mui-focused": {
        transformOrigin: "center",
        marginLeft: "0 auto",
      },
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fd624c",
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fd624c",
      },
      "&:hover fieldset": {
        borderColor: "#fd624c",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fd624c",
        textAlign: "center",
      },
    },
  });

  return (
    <>
      <div className="">
        <h4>Communication</h4>
        <div className="row ">
          <div className="d-flex flex-column  flex-lg-row">
            <div className=" d-flex flex-column">
              <CssTextField
                sx={{
                  minWidth: 200,
                }}
                className="mt-3 col-12 col-lg-4 my-2 p-1"
                id="outlined-basic"
                label="Email"
                variant="outlined"
              />
              <CssTextField
                sx={{
                  minWidth: 200,
                }}
                className="mt-3 col-12 col-lg-4 my-2 p-1"
                id="outlined-basic"
                label="Email alternate"
                variant="outlined"
              />
              <CssTextField
                sx={{
                  minWidth: 200,
                }}
                className="mt-3 col-12 col-lg-4 my-2 p-1"
                id="outlined-basic"
                label="Fax"
                variant="outlined"
              />
              <CssTextField
                sx={{
                  minWidth: 200,
                }}
                className="mt-3 col-12 col-lg-4 my-2 p-1"
                id="outlined-basic"
                label="Website"
                variant="outlined"
              />
            </div>
            <div className="col-12 col-lg-4  p-0">
              <div className=" d-flex  flex-lg-row">
                <CssTextField
                  sx={{
                    minWidth: 200,
                  }}
                  className="mt-3 col-12 col-lg-4 my-2 p-1"
                  id="outlined-basic"
                  label="Telephone 1"
                  variant="outlined"
                />
                <span className="fst-italic fs-6 mt-4 ms-2 text-muted">
                  Home
                </span>
              </div>
              <div className=" d-flex  flex-lg-row">
                <CssTextField
                  sx={{
                    minWidth: 200,
                  }}
                  className="mt-3 col-12 col-lg-8 my-2 p-1"
                  id="outlined-basic"
                  label="Telephone 2"
                  variant="outlined"
                />
                <span className="fst-italic fs-6 mt-4 ms-2 text-muted">
                  Work
                </span>
              </div>
              <div className=" d-flex  flex-lg-row">
                <CssTextField
                  sx={{
                    minWidth: 200,
                  }}
                  className="mt-3 col-12 col-lg-4 my-2 p-1"
                  id="outlined-basic"
                  label="Telephone 3"
                  variant="outlined"
                />
                <span className="fst-italic fs-6 mt-4 ms-2 text-muted">
                  Mobile
                </span>
              </div>
              <div className="d-flex   flex-lg-row">
                <CssTextField
                  sx={{
                    minWidth: 200,
                  }}
                  className="mt-3 col-12 col-lg-4 my-2 p-1"
                  id="outlined-basic"
                  label="Telephone 4"
                  variant="outlined"
                />
                <span className="fst-italic fs-6 mt-4 ms-2 text-muted">
                  Other
                </span>
              </div>
              <CssTextField
                sx={{
                  minWidth: 200,
                }}
                className="mt-3 col-12 col-lg-4 my-2 p-1"
                id="outlined-basic"
                label="Skype ID"
                variant="outlined"
              />
            </div>
          </div>
        </div>
        <div className=" col-12 d-flex col-lg-4 my-2">
          <span
            style={{
              marginTop: "10px",
              backgroundColor: "#e4e9ef",
              // whiteSpace: "break-spaces",
            }}
            className=" ms-1 rounded fs-6 p-2"
          >
            + Add communication account
          </span>
        </div>
        <hr className="mt-5" />
      </div>
    </>
  );
}
