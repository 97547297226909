import React, { useEffect, useState } from "react";

import toast, { Toaster } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import "../styles/Login.css";
import LoginJpg from "../clouboard_logo/login.jpg";
import Logo from "../clouboard_logo/logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../Features/authSlice";
import bcrypt from "bcryptjs";

export default function Login() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const handleClick = async (e) => {
    e.preventDefault();
    // emaili yollluyoruz cunku apiye get isteği yapıyoruz
    dispatch(loginUser({ email, hashedPassword }));
  };

  useEffect(() => {
    if (user) navigate("/");
  }, [user]);

  // if (user) return navigate("/");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const salt = bcrypt.genSaltSync(10);
  const passwd = bcrypt.hashSync(email + password, salt);
  const hashedPassword = passwd.toString();

  return (
    <div className="">
      <div className="container-fluid p-0">
        <div className="row">
          <div className="d-none d-lg-block col-9 vh-100">
            <img
              src={LoginJpg}
              alt=""
              style={{
                width: "100%",
                height: "100%",
                verticalAlign: "middle",
                objectFit: "cover",
              }}
            />
          </div>
          <div className="col-12 col-lg-3 vh-100 d-flex align-items-center justify-content-center px-lg-0">
            <div className="login-page-form shadow">
              <img src={Logo} alt="Logo" className="mb-4" />
              <div className="mt-3">
                <input
                  required={true}
                  autoComplete="true"
                  type="Email"
                  className=" bordr  w-100"
                  id="formGroupExampleInput"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </div>
              <div className="mt-4 ">
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required={true}
                  autoComplete={"true"}
                  type="password"
                  className=" bordr  w-100"
                  id="password"
                  placeholder="Password"
                />
              </div>

              <div className=" text-center my-auto mt-5">
                <button
                  onClick={handleClick}
                  type="submit"
                  className="btn-color col-8"
                >
                  Login
                </button>
              </div>
              <div className="mt-2 text-center">
                <p
                  onClick={() => alert("Henüz yapılmadı :)")}
                  type="submit"
                  className="fw-light fs-6 forget   col-12"
                >
                  Forget Password?
                </p>
              </div>
              <div className="mt-5 text-center">
                <p className="fs-6 haveAnAccount  col-12">
                  Don’t you have an account,
                  <Link
                    to="/"
                    onClick={(e) => {
                      e.preventDefault();
                      toast.loading("yapım aşamasında");
                    }}
                    className="nav-link signup fs-6 p-0 "
                  >
                    Sign up
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
