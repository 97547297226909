import React, { useState } from "react";
import { useDispatch } from "react-redux";
// import Create from "../clouboard_logo/Create.svg";
import { postUser } from "../Features/userSlice";
import { VscClose } from "react-icons/vsc";
import UserMasterData from "./UserAdd/UserMasterData";
import UserComminication from "./UserAdd/UserComminication";
import UserÇalışanYetkileri from "./UserAdd/UserÇalışanYetkileri";

export default function UsersAddModal() {
  const dispatch = useDispatch();
  const Inputs = [
    {
      name: "access_token",
      type: "text",
    },
    {
      name: "actif",
      type: "number",
    },
    {
      name: "admin",
      type: "number",
    },
    {
      name: "birthdate",
      type: "text",
    },
    {
      name: "created_at",
      type: "number",
    },

    {
      name: "created_by",
      type: "number",
    },
    {
      name: "email",
      type: "text",
    },
    {
      name: "firstname",
      type: "text",
    },
    {
      name: "photo",
      type: "file",
    },
    {
      name: "privilege",
      type: "number",
    },
    {
      name: "pseudo",
      type: "text",
    },
    {
      name: "pwd",
      type: "text",
    },
    {
      name: "remark",
      type: "text",
    },
    {
      name: "reset_token",
      type: "text",
    },
    {
      name: "surname",
      type: "text",
    },
    {
      name: "titles",
      type: "number",
    },
    {
      name: "updated_at",
      type: "number",
    },
    {
      name: "updated_by",
      type: "number",
    },
  ];
  const [values, setValues] = useState({
    access_token: "",
    actif: "",
    admin: "",
    birthdate: "",
    created_at: "",
    created_by: "",
    email: "",
    firstname: "",
    photo: "",
    privilege: "",
    pseudo: "",
    pwd: "",
    remark: "",
    reset_token: "",
    surname: "",
    titles: "",
    updated_at: "",
    updated_by: "",
  });
  return (
    <>
      <div
        className="modal fade"
        id="userModals"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-lg modal-dialog ">
          <div className="modal-content  ">
            <div className="modal-header  border-bottom-0">
              <h6
                className="modal-title mx-auto d-flex "
                id="staticBackdropLabel"
              >
                {/* <img width={"40px"} src={Edit} alt="" /> */}
                <span className="fs-5 my-auto">Add User</span>
              </h6>
              <button
                style={{
                  backgroundColor: "#fd624c",
                  color: "#fff",
                  fontWeight: "bold",
                  outline: "",
                }}
                className="btn text-end"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <VscClose />
              </button>
            </div>
            <div className="modal-body d-flex flex-column flex-lg-row d-flex border border-0">
              <div className="col-2 p-0 mx-auto">
                <label
                  htmlFor="icon-button-file"
                  className="rounded mx-auto mt-5 "
                  style={{
                    backgroundColor: "#e4e9ef",
                    height: "90px",
                    width: "85px",
                  }}
                />
              </div>
              <div className="">
                <UserMasterData />
                <UserComminication />
                <UserÇalışanYetkileri />
              </div>
            </div>
            <div className="modal-footer  border border-0">
              <button
                style={{
                  backgroundColor: "#fd624c",
                  color: "#fff",
                  fontWeight: "bold",
                  outline: "",
                }}
                className="btn text-end"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
{
  /* <>
   <form>
                {Inputs.map((input) => {
                  return (
                    <div key={input.name} className="form-group">
                      <label htmlFor={input.name}>{input.name}</label>
                      <input
                        type={input.type}
                        className="form-control"
                        id={input.name}
                        value={values[input.name]}
                        onChange={(e) =>
                          setValues({ ...values, [input.name]: e.target.value })
                        }
                      />
                    </div>
                  );
                })}
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    dispatch(postUser(values));
                  }}
                >
                  Add
                </button>
              </form>

</> */
}
