import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// UserSLice icerı aldık cunku Dispatch burada yapılcak
import { fetchUser, setEditUser } from "../Features/userSlice";
// import { postUser } from "../Features/userSlice";
import { deleteUser } from "../Features/userSlice";
import { getUser } from "../Features/userSlice";
import { editUser } from "../Features/userSlice";
import "../styles/Navbar.css";

import Delete from "../clouboard_logo/Delete.svg";
import Edit from "../clouboard_logo/Edit.svg";
// import { toast, ToastContainer } from "react-toastify";
import { FaRegComment } from "react-icons/fa";
import UsersAddModal from "../Modal/UsersAddModal";
import Create from "../clouboard_logo/Create.svg";

export default function Users() {
  // const notify = () =>
  //   toast.success("successfully", {
  //     position: "top-right",
  //     autoClose: 5000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //   });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUser());
  }, []);
  const { user, editUserData } = useSelector((state) => state.user);
  const [values, setValues] = useState({
    access_token: "",
    actif: "",
    admin: "",
    birthdate: "",
    created_at: "",
    created_by: "",
    email: "",
    firstname: "",
    photo: "",
    privilege: "",
    pseudo: "",
    pwd: "",
    remark: "",
    reset_token: "",
    surname: "",
    titles: "",
    updated_at: "",
    updated_by: "",
  });
  const Inputs = [
    {
      name: "access_token",
      type: "text",
    },
    {
      name: "actif",
      type: "number",
    },
    {
      name: "admin",
      type: "number",
    },
    {
      name: "birthdate",
      type: "text",
    },
    {
      name: "created_at",
      type: "number",
    },

    {
      name: "created_by",
      type: "number",
    },
    {
      name: "email",
      type: "text",
    },
    {
      name: "firstname",
      type: "text",
    },
    {
      name: "photo",
      type: "file",
    },
    {
      name: "privilege",
      type: "number",
    },
    {
      name: "pseudo",
      type: "text",
    },
    {
      name: "pwd",
      type: "text",
    },
    {
      name: "remark",
      type: "text",
    },
    {
      name: "reset_token",
      type: "text",
    },
    {
      name: "surname",
      type: "text",
    },
    {
      name: "titles",
      type: "number",
    },
    {
      name: "updated_at",
      type: "number",
    },
    {
      name: "updated_by",
      type: "number",
    },
  ];
  return (
    <>
      <div className="Users">
        {/* <ToastContainer autoClose={8000} /> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col p-5">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">
                      <img
                        data-bs-toggle="modal"
                        data-bs-target="#userModals"
                        width={"30px"}
                        src={Create}
                        alt=""
                      />
                      <UsersAddModal />
                    </th>
                    <th scope="col">Firstname</th>
                    <th scope="col">Surname</th>
                    <th scope="col">Email</th>
                    <th scope="col">Transactions</th>
                  </tr>
                </thead>
                <tbody>
                  {user &&
                    editUser &&
                    user.map((user, idx) => {
                      const { id, firstname, email, surname } = user;
                      return (
                        <tr key={idx}>
                          <th scope="row">{id}</th>
                          <td>{firstname}</td>
                          <td>{surname}</td>
                          <td>{email}</td>
                          <td className="d-flex">
                            <span
                              onClick={() => {
                                console.log({ id });
                                dispatch(deleteUser(id));
                                // window.location.reload();
                              }}
                            >
                              <img width={"30px"} src={Delete} alt="" />
                            </span>
                            <span
                              className=" ms-2"
                              data-bs-toggle="modal"
                              data-bs-target="#editModals"
                              onClick={() => {
                                dispatch(getUser(id));
                              }}
                            >
                              <img width={"30px"} src={Edit} alt="" />
                            </span>
                            <span
                              className="ms-2"
                              data-bs-toggle="modal"
                              data-bs-target="#CommentModal"
                              onClick={() => alert(id)}
                            >
                              <FaRegComment />
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}

      {/* edit modal */}
      <div
        className="modal fade"
        id="editModals"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Editle
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {editUserData && (
                <form>
                  {Inputs.map((input) => {
                    return (
                      <div key={input.name} className="form-group">
                        <label htmlFor={input.name}>{input.name}</label>
                        {/* inp */}
                        <input
                          type={input.type}
                          className="form-control"
                          id={input.name}
                          name={input.name}
                          value={editUserData[input.name]}
                          onChange={(e) =>
                            dispatch(
                              setEditUser({
                                name: e.target.name,
                                value: e.target.value,
                              })
                            )
                          }
                        />
                      </div>
                    );
                  })}
                  <button
                    type="button"
                    className="btn btn-primary mt-1 "
                    data-bs-dismiss="modal"
                    onClick={() => {
                      //
                      dispatch(editUser(editUserData));
                      // notify();
                    }}
                  >
                    Edit
                  </button>
                </form>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary mt-1"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
