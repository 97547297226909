import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";

const initialState = {
  user: null,
  // edit user ıcın yenı initial state
  editUserData: null,
  loading: false,
  error: null,
};

// // edit için get işlemi
const getUser = createAsyncThunk("user/getUser", async (id) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_KEY}basic/web/index.php/users/${id}`
  );
  return response.data;
});

// edit işlemleri put işlemi
const editUser = createAsyncThunk("user/editUser", async (values) => {
  const response = await axios.put(
    `${process.env.REACT_APP_API_KEY}basic/web/index.php/users/${values.id}`,
    values
  );
  return response.data;
});
// edit işlemleri son

const fetchUser = createAsyncThunk("user/fetchUser", async () => {
  const response = await axios(
    `${process.env.REACT_APP_API_KEY}basic/web/index.php/users`
  );
  return response.data;
});
/* Post İşlemleri */
const postUser = createAsyncThunk("user/postUser", async (values) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_KEY}basic/web/index.php/users`,
    values
  );

  return response.data;
});
/* Post İşlemleri Son */
/* Delete işlemleri */
const deleteUser = createAsyncThunk("user/deleteUser", async (id) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_API_KEY}basic/web/index.php/users/${id}`
  );
  return id;
  // return response.data;
});
/* Delete işlemleri Son */

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    add: (state, action) => {
      state.user.push(action.payload);
    },
    setEditUser: (state, action) => {
      state.editUserData[action.payload.name] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(fetchUser.pending, (state, action) => {
        // loading yapalım
        state.loading = true;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        // hatayı handle et
        state.loading = false;
        alert("hata");
      })
      /* post builder*/
      .addCase(postUser.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.user = [...state.user, action.payload];
        toast.success("Success");
      })
      .addCase(postUser.pending, (state, action) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(postUser.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        toast.error("işlem Unsuccess");
      })
      /* post builder son */
      /* delete builder*/
      .addCase(deleteUser.fulfilled, (state, action) => {
        console.log("deleted user id");
        console.log(action.payload);
        state.loading = false;
        state.error = false;
        // eğer logda proxy yazıyorsa current kullanılcak
        const filtered = current(state.user).filter(
          (user) => user.id !== action.payload
        );
        state.user = filtered;
        toast.success("Deleted");
      })
      .addCase(deleteUser.pending, (state, action) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        toast.error("işlem başarısız");
      })
      /* delete builder son*/

      // get builder
      .addCase(getUser.fulfilled, (state, action) => {
        state.loading = false;
        state.editUserData = action.payload;
      })
      .addCase(getUser.pending, (state, action) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      // get builder son

      /* edit builder*/
      .addCase(editUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = state.user.map((user) =>
          user.id === action.payload.id ? action.payload : user
        );
        toast.success("İşlem Başarılı");
      })
      .addCase(editUser.pending, (state, action) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(editUser.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        toast.error("İşlem Başarısız");
      });
  },
});

// Action creators are generated for each case reducer function
export const { add, setEditUser } = userSlice.actions;
export { fetchUser, postUser, deleteUser, getUser, editUser };
export default userSlice.reducer;
