import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";
import "../../styles/ContactsAdd.css";
import { styled } from "@mui/material/styles";

export default function MasterData() {
  const CssTextField = styled(TextField)({
    "& label": {
      // eğer laellerı focus olmadan ortalamak ıstersek
      width: "90%",
      textAlign: "center",
      marginTop: "3.5px",
    },
    /*Settings Son */
    "& label.Mui-focused": {
      color: "#fd624c",

      textAlign: "center",
      transformOrigin: "center",
      "&.Mui-focused": {
        transformOrigin: "center",
        marginLeft: "0 auto",
      },
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fd624c",
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fd624c",
      },
      "&:hover fieldset": {
        borderColor: "#fd624c",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fd624c",
        textAlign: "center",
      },
    },
  });

  return (
    <div>
      <h4>Master Data</h4>
      <div className="mt-4 ">
        <div className="d-flex flex-column  flex-lg-row  mt-3">
          <CssTextField
            required
            className="col-12  col-lg-4 my-2 p-1"
            sx={{
              width: 200,
              label: {
                alignItems: "center",
              },
            }}
            label="Contact no"
            id="custom-css-outlined-input"
          />
          <FormControl className="ms-4 col-12 col-lg-8 my-auto ">
            <RadioGroup
              row
              className="d-flex flex-column  flex-lg-row"
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                className=""
                value={"Company"}
                control={
                  <Radio
                    sx={{
                      "& .MuiSvgIcon-root": {
                        color: "#fd624c",
                      },
                    }}
                  />
                }
                label="Company"
              />
              <FormControlLabel
                className=""
                value={"Private"}
                control={
                  <Radio
                    sx={{
                      "& .MuiSvgIcon-root": {
                        color: "#fd624c",
                      },
                    }}
                  />
                }
                label="Private"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div className="d-flex flex-column col-lg-mx-auto flex-lg-row  mt-3 ">
          <CssTextField
            className="m-auto col-12 col-lg-4 my-2 p-1"
            sx={{
              minWidth: 200,
            }}
            variant="outlined"
            label="Form of address"
            id="custom-css-outlined-input"
          />
          <CssTextField
            className="m-auto  col-12 col-lg-4  my-2  p-1"
            sx={{
              minWidth: 200,
            }}
            variant="outlined"
            label="Last Name"
            id="custom-css-outlined-input"
          />
          <CssTextField
            className="m-auto col-12 col-lg-4 my-2 p-1"
            sx={{
              minWidth: 200,
            }}
            variant="outlined"
            label="First Name"
            id="custom-css-outlined-input"
          />
        </div>
        <CssTextField
          id="outlined-multiline-outlined"
          multiline
          rows="4"
          // value={commentValues.comment}
          // onChange={handleChange("comment")}
          className="mt-3 col-12 col-lg-12 my-2 p-1"
          label="Address..."
          variant="outlined"
        />
        <div className="d-flex flex-column col-lg-mx-auto flex-lg-row    mt-3  ">
          <FormControl
            className="m-auto col-12 col-lg-4 my-2 p-1"
            sx={{ width: 200 }}
          >
            <InputLabel sx={{ color: "#fd624c" }} id="demo-select-small">
              Admin
            </InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select"
              // value={"age"}
              label="Admin"
              // onChange={handleChange}
            >
              <MenuItem
                sx={{
                  color: "#fd624c",
                }}
                value=""
              >
                <em>None</em>
              </MenuItem>
              <MenuItem
                sx={{
                  color: "#fd624c",
                }}
                value={10}
              >
                Ten
              </MenuItem>
            </Select>
          </FormControl>

          <FormControl
            className=" m-auto col-12 col-lg-4 my-2 p-1"
            sx={{ width: 200 }}
          >
            <InputLabel sx={{ color: "#fd624c" }} id="demo-select-small">
              City
            </InputLabel>
            <Select
              variant="outlined"
              labelId="demo-select-small"
              id="demo-select"
              // value={"age"}
              label="City"
              // onChange={handleChange}
            >
              <MenuItem sx={{ color: "#fd624c" }} value="">
                <em>None</em>
              </MenuItem>
              <MenuItem sx={{ color: "#fd624c" }} value={10}>
                Ten
              </MenuItem>
            </Select>
          </FormControl>

          <FormControl
            sx={{ color: "#fd624c", width: 200 }}
            className=" m-auto col-12 col-lg-4 my-2 p-1"
          >
            <InputLabel sx={{ color: "#fd624c" }} id="demo-select-small">
              Country
            </InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select"
              // value={"age"}
              label="Country"
              // onChange={handleChange}
            >
              <MenuItem sx={{ color: "#fd624c" }} value="">
                <em>None</em>
              </MenuItem>
              <MenuItem sx={{ color: "#fd624c" }} value={10}>
                Ten
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className=" mt-2 col-12 col-lg-8 ">
          <span
            style={{ backgroundColor: "#e4e9ef" }}
            className=" rounded p-2 ms-1 fs-6 "
          >
            + Add master data
          </span>
        </div>
      </div>
      <hr className="mt-5" />
    </div>
  );
}
