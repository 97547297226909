import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";
import React from "react";
import "../../styles/ContactsAdd.css";
export default function MoreContacts() {
  const CssTextField = styled(TextField)({
    "& label": {
      // eğer laellerı focus olmadan ortalamak ıstersek
      width: "90%",
      textAlign: "center",
      marginTop: "3.5px",
    },
    /*Settings Son */
    "& label.Mui-focused": {
      color: "#fd624c",

      textAlign: "center",
      transformOrigin: "center",
      "&.Mui-focused": {
        transformOrigin: "center",
        marginLeft: "0 auto",
      },
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fd624c",
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fd624c",
      },
      "&:hover fieldset": {
        borderColor: "#fd624c",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fd624c",
        textAlign: "center",
      },
    },
  });
  return (
    <div>
      <h4 style={{ whiteSpace: "break-spaces" }}>More Contact Information</h4>
      <div className="mt-2">
        <div className="row">
          <div className="col d-flex flex-column flex-lg-row">
            <div className="col d-flex flex-column ">
              <CssTextField
                sx={{
                  minWidth: 200,
                }}
                className="mt-3 col-12 col-lg-4 my-2 p-1"
                id="outlined-basic"
                label="Number of employes"
                variant="outlined"
              />
              <CssTextField
                sx={{
                  minWidth: 200,
                }}
                className="mt-3 col-12 col-lg-4 my-2 p-1"
                id="outlined-basic"
                label="Commercial register no"
                variant="outlined"
              />
            </div>
            <div className="col d-flex flex-column">
              <CssTextField
                sx={{
                  minWidth: 200,
                }}
                className="mt-3 col-12 col-lg-4 my-2 p-1"
                id="outlined-basic"
                label="VAT no"
                variant="outlined"
              />
              <CssTextField
                sx={{
                  minWidth: 200,
                }}
                className="mt-3 col-12 col-lg-4 my-2 p-1"
                id="outlined-basic"
                label="VAT Identification number"
                variant="outlined"
              />
              <CssTextField
                sx={{
                  minWidth: 200,
                }}
                className="mt-3 col-12 col-lg-4 my-2 p-1"
                id="outlined-basic"
                label="Birthday"
                variant="outlined"
              />
            </div>
          </div>
        </div>
        <div className="text-end d-flex flex-column justify-content-around flex-lg-row  ">
          <span
            style={{
              marginTop: "10px",
              backgroundColor: "#e4e9ef",
              whiteSpace: "break-spaces",
            }}
            className="rounded ms-1 p-1 fs-6 me-auto"
          >
            + Add contact information
          </span>

          <button
            id="save"
            style={{
              backgroundColor: " #fd624c",
              padding: "4px 13px",
              marginTop: "25px",
            }}
            className="btn text-bold text-white  my-auto"
          >
            SAVE
          </button>
        </div>
      </div>
    </div>
  );
}
