import React, { useRef, useState } from "react";
import "../styles/Navbar.css";
import "../styles/Nav.css";
import "../styles/NavbarTablet-Mobile.css";
/* Hamburger Icon */
import Haber from "../clouboard_logo/Haber.svg";
import Satıs from "../clouboard_logo/Satıs.svg";
import Rapor from "../clouboard_logo/Rapor.svg";
import Etkınlık from "../clouboard_logo/Etkınlık.svg";
import Contacts from "../clouboard_logo/Contacts.svg";
import Ajanda from "../clouboard_logo/Ajanda.svg";
import Urun from "../clouboard_logo/Urun.svg";
import User from "../clouboard_logo/User.svg";
import Settings from "../clouboard_logo/Settings.svg";
import NavbarLogo from "../clouboard_logo/navbarLogo.ico";

/* */
// İconlar import edildi
import Message from "../clouboard_logo/sagnavbaricons/Message.svg";
import Saved from "../clouboard_logo/sagnavbaricons/Saved.svg";
import Notifications from "../clouboard_logo/sagnavbaricons/Notifications.svg";
import Hamburger from "../clouboard_logo/Mobile_nav_hamburger/Hamburger.svg";
import { BsChevronDown, BsChevronUp, BsCircle } from "react-icons/bs";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import Create from "../clouboard_logo/Create.svg";
import { Link, NavLink, useLocation } from "react-router-dom";
import pageHeader from "../data/pageHeader";
//----------Alert-----------------
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// tipy
import Tippy from "@tippy.js/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";
//---------Tip son-----------------

import { useDispatch } from "react-redux";
import ContactAdd from "../Modal/ContactsAdd/ContactAdd";
import NavProfile from "./NavProfile";
//-----------------------------
export default function Nav() {
  const [isHovering, setIsHovering] = useState(true);

  const DynamicLink = [
    {
      Name: "Activity",
      url: "/Haber",
      img: Haber,
    },

    {
      Name: "Sales",
      url: "/Sales",
      img: Satıs,
    },
    {
      Name: "Reports",
      url: "/Reports",
      img: Rapor,
    },
    {
      Name: "Tasks",
      url: "/Tasks",
      img: Etkınlık,
    },

    {
      Name: "Contacts",
      url: "/Contacts",
      img: Contacts,
      sublinks: [
        { name: "Contacts", url: "/Contacts" },
        { name: "Companies", url: "/Companies" },
      ],
    },
    {
      Name: "Products",
      url: "/Products",
      img: Urun,
    },
    {
      Name: "Calendar",
      url: "/Calendar",
      img: Ajanda,
    },
    {
      Name: "Settings",
      url: "/Settings",
      img: Settings,
    },

    // {
    //   Name: "Users",
    //   url: "/Users",
    //   img: User,
    // },
  ];

  const ref = useRef();
  const dispatch = useDispatch();
  // const notify = () => toast("Complated!");

  let location = useLocation();
  const page = location.pathname.split("/")[1];

  return (
    <div className="Nav  d-flex ">
      <div className="container   d-flex">
        <span
          className=" hamburger nav-link transparent "
          data-bs-toggle="offcanvas"
          type="button"
          data-bs-target="#offcanvasExample"
          role="button"
          aria-controls="offcanvasExample"
        >
          <img width={"25px"} className="mt-2" src={Hamburger} alt="" />
        </span>
        <div
          style={{ backgroundColor: "#343A40" }}
          className="offcanvas z-index offcanvas-start"
          tabIndex="-1"
          id="offcanvasExample"
          aria-labelledby="offcanvasExampleLabel"
        >
          <div className="offcanvas-header">
            {/* <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            /> */}
          </div>
          <div className="offcanvas-body ">
            <ul className="navbar-nav  mb-lg-0 ms-4">
              <li className="nav-item ms-4">
                <Link to="/" className="m-auto" data-bs-dismiss="offcanvas">
                  <img
                    width={"170px"}
                    style={{ aspectRatio: 1 / 0.2 }}
                    src={NavbarLogo}
                    alt="Clouboard"
                  />
                </Link>
              </li>
              {DynamicLink.map((item, index) => {
                const { Name, url, img, sublinks } = item;

                return (
                  <li key={index} className="nav-item  mt-4">
                    <NavLink
                      data-bs-dismiss={Name !== "Contacts" && "offcanvas"}
                      to={url}
                      className={` navText ms-3 link ${({ isActive }) =>
                        isActive && "active"}`}
                    >
                      <img
                        id="img"
                        width={"30px"}
                        src={img}
                        alt={Name}
                        className={` navText ms-3 me-2 active link ${({
                          isActive,
                        }) => isActive && "active"}`}
                      />
                      {Name}
                      {sublinks && (
                        <>
                          <span
                            className="dropdown-togggle text-white ms-5"
                            data-bs-toggle="collapse"
                            href="#collapseExample"
                            role="button"
                            aria-expanded="false"
                            aria-haspopup="true"
                            aria-controls="collapseExample"
                            onClick={() => setIsHovering(!isHovering)}
                          >
                            {isHovering ? (
                              <BsChevronDown
                                className={` navText  active link ${({
                                  isActive,
                                }) => isActive && "active"}`}
                              />
                            ) : (
                              <BsChevronUp
                                className={` navText active link ${({
                                  isActive,
                                }) => isActive && "active"}`}
                              />
                            )}
                          </span>

                          <ul className="collapse" id="collapseExample">
                            {sublinks.map((item, idx) => {
                              return (
                                <li key={idx} className="ms-4">
                                  <span className="dropdown-item ms-3 nav-link text-white bg-transparent">
                                    {item.name}
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </>
                      )}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="navbar-brand p-0 d-flex">
          {pageHeader[page] ? (
            <img className="m-2" width={"30px"} src={pageHeader[page]} />
          ) : null}
          <span className="p-2">{page}</span>
          <ContactAdd page={page} />
          <div>
            <span
              className="ms-2  plus"
              data-bs-toggle="modal"
              data-bs-target="#exampleModalsatis"
            >
              {page === "Satis" && (
                <img
                  width={"30px"}
                  className="mt-2"
                  src={Create}
                  alt="Satış iconu"
                />
              )}
            </span>
            <div
              className="modal mt-4 fade"
              id="exampleModalsatis"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content plusRadius ">
                  <div className="modal-body">Satis Add</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav className="navbar position-absolute top-0 end-0   navbar-expand-lg ">
        <div className="container">
          <Tippy
            duration={1000}
            theme="light-border"
            animation="scale"
            placement="left"
            arrow={false}
            content={
              <ul className="navbar-nav ms-auto  d-flex flex-row mb-2 mb-lg-0  ">
                <li className="nav-item">
                  <img
                    width={"60px"}
                    style={{ aspectRatio: "1/0.4" }}
                    src={Message}
                  />
                </li>
                <li className="nav-item">
                  <img
                    width={"60px"}
                    style={{ aspectRatio: "1/0.4" }}
                    src={Saved}
                  />
                </li>
                <li className="nav-item">
                  <img
                    width={"60px"}
                    style={{ aspectRatio: "1/0.4" }}
                    src={Notifications}
                  />
                </li>
                <li className="nav-item">
                  <div
                    className="rounded-circle"
                    style={{
                      padding: "15px",
                      border: "2px solid red",
                      backgroundColor: "white",
                    }}
                    width={"50px"}
                  />
                </li>
              </ul>
            }
          >
            <span className="popovers">
              <BsCircle />
            </span>
          </Tippy>

          <div
            className="collapse navbar-collapse  "
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0  ">
              <li className="nav-item">
                <img
                  width={"60px"}
                  style={{ aspectRatio: "1/0.4" }}
                  src={Message}
                />
              </li>
              <li className="nav-item">
                <img
                  width={"60px"}
                  style={{ aspectRatio: "1/0.4" }}
                  src={Saved}
                />
              </li>
              <li className="nav-item">
                <img
                  width={"60px"}
                  style={{ aspectRatio: "1/0.4" }}
                  src={Notifications}
                />
              </li>

              <NavProfile />
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
