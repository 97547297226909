import React, { useState } from "react";

const CommentDisplay = ({ comment }) => {
  const [readMore, setReadMore] = useState(false);
  return (
    <>
      <p jsx="true" className="p-0">
        {comment.substring(0, 200)}
        {readMore ? comment : `${comment.substring(0, 50)}`}
        {comment.length > 30 && (
          <span
            style={{
              color: "#0092ff",
              cursor: "pointer",
            }}
            onClick={() => setReadMore(!readMore)}
          >
            {readMore ? "Show Less" : " Read More"}
          </span>
        )}
      </p>
    </>
  );
};

export default CommentDisplay;
