import React from "react";
import { BsArrowDownUp } from "react-icons/bs";
import Create from "./Create.svg";

export default function Bugün() {
  return (
    <div
      className="accordion  mx-auto accordion-flush mt-4  "
      id="accordionFlushExample2"
    >
      <div className="card accordion-item ">
        <div className="">
          <h2 className="accordion-header" id="flush-headingOne">
            <span
              style={{ borderRadius: "20px" }}
              className="accordion-button collapsed  px-3"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseTwo"
              aria-expanded="false"
              aria-controls="flush-collapseTwo"
            >
              <div className="d-flex">
                <BsArrowDownUp className=" my-auto" style={{ width: "28px" }} />
                <div className="badges">
                  <h5
                    style={{
                      whiteSpace: "nowrap",
                      color: "#323441",
                    }}
                    className="ms-3 my-auto fw-bold"
                  >
                    Bu gün
                  </h5>
                </div>
                <div className="ms-3 my-auto">
                  <span
                    style={{
                      padding: "5px 13px  ",
                      backgroundColor: "#feb302",
                    }}
                    className=" rounded-circle  text-dark "
                  ></span>
                </div>
              </div>
            </span>
          </h2>
          <div
            id="flush-collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingOne"
            data-bs-parefw-bold
            nt="#accordionFlushExample2"
          >
            <div className="accordion-body p-0">
              <div className="card-body p-0">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Bu Hafta</th>
                      <th scope="col">Grup adı </th>
                      <th scope="col">Durum</th>
                      <th scope="col">Tarih</th>
                    </tr>
                  </thead>
                  <tbody style={{ borderTop: "none" }}>
                    <tr>
                      <th scope="row ">
                        <div className="d-flex flex-row">
                          <div
                            className="my-auto "
                            style={{
                              backgroundColor: "#fd624c",
                              width: "25px",
                              height: "10px",
                            }}
                          />
                          <span className="ms-1"> Görev 1</span>
                        </div>
                      </th>
                      <td className="d-flex">
                        <img width={"28px"} src={Create} alt="" />
                        <div
                          style={{
                            padding: "15px",
                            backgroundColor: "#b3e5fc",
                          }}
                          className="rounded-circle"
                        />
                      </td>
                      <td>
                        <span
                          style={{
                            padding: "5px",
                            borderRadius: "5px",
                            backgroundColor: "#c8e6c9",
                            color: "#256029",
                          }}
                        >
                          Tamamlandı
                        </span>
                      </td>
                      <td>26.05.2022</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <th scope="row ">
                          <div className="d-flex flex-row">
                            <div
                              className="my-auto "
                              style={{
                                backgroundColor: "#fd624c",
                                width: "25px",
                                height: "10px",
                              }}
                            />
                            <span className="ms-1"> Görev 2</span>
                          </div>
                        </th>
                      </th>
                      <td className="d-flex">
                        <img width={"28px"} src={Create} alt="" />
                        <div
                          style={{ padding: "15px" }}
                          className=" bg-info rounded-circle"
                        />
                      </td>
                      <td>
                        <span
                          style={{
                            padding: "5px",
                            borderRadius: "5px",
                            backgroundColor: "#ffcdd2",
                            color: "#fc062a",
                          }}
                        >
                          Çalışılıyor
                        </span>
                      </td>
                      <td>26.06.2022</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <div className="d-flex flex-row">
                          <div
                            className="my-auto "
                            style={{
                              backgroundColor: "#fd624c",
                              width: "25px",
                              height: "10px",
                            }}
                          />
                          <span className="ms-1"> Görev 3</span>
                        </div>
                      </th>
                      <td className="d-flex">
                        <img width={"28px"} src={Create} alt="" />
                        <div
                          style={{
                            padding: "15px",
                            backgroundColor: "#0092ff",
                          }}
                          className="rounded-circle"
                        />
                      </td>
                      <td>
                        <span
                          style={{
                            padding: "5px",
                            borderRadius: "5px",
                            backgroundColor: "#c8e6c9",
                            color: "#256029",
                          }}
                        >
                          Tamamlandı
                        </span>
                      </td>
                      <td>26.05.2022</td>
                    </tr>

                    {/* Görev ekle */}
                    <tr>
                      <th scope="row">
                        <div className="d-flex flex-row">
                          <input
                            style={{ border: "none", outline: "none" }}
                            className="ms-1"
                            type="text"
                            placeholder="+ Görev ekle.."
                          />
                        </div>
                      </th>
                      <td className="d-flex">
                        <img width={"28px"} src={Create} alt="" />
                      </td>
                      <td>
                        <select
                          style={{
                            border: "none",
                            outline: "none",
                            backgroundColor: "transparent",
                            // color: "#FD624C",
                          }}
                          classname="form-select text-secondary"
                          aria-label="Default select example"
                        >
                          <option selected>Durum</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </td>
                      <td>
                        <input
                          style={{ border: "none", outline: "none" }}
                          className="ms-1"
                          type="date"
                          placeholder="+ Tarih ekle.."
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
