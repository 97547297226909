import React from "react";

import Gecikmiş from "./Accordionlar/Gecikmiş";
import Buhafta from "./Accordionlar/Buhafta";
import Bugün from "./Accordionlar/Bugün";
import Gelecekhafta from "./Accordionlar/Gelecekhafta";
import DahaSonra from "./Accordionlar/DahaSonra";

export default function MyWorks() {
  return (
    <div className="row">
      {/* Gecikmiş */}
      <Gecikmiş />
      {/* Bu Gün */}
      <Bugün />
      {/* Bu hafta */}
      <Buhafta />
      {/* Gelecek Hafta */}
      <Gelecekhafta />
      {/* Daha Sonra */}
      <DahaSonra />
    </div>
  );
}
